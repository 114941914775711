import React from 'react';
import Layout from '../../../components/common/layout/Layout';
import CustomerListComponent from '../../../components/dashboard/customer/CustomerListComponent';

const CustomerList = () => {
    return (
        <Layout>
            <CustomerListComponent />
        </Layout>
    );
};

export default CustomerList;