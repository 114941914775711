import axios from 'axios';
export async function employeelist() {
    // var AuthUserToken = localStorage.getItem("AuthUserToken")
    // axios.defaults.headers.common['AuthUserToken'] = AuthUserToken;

    let data = await axios.get(process.env.REACT_APP_BACK_END+"/allEmployees" );

    return data.data;
}

export async function employeeStorge(formData) {
    let userData =  localStorage.getItem("userData");
    let userInfo = JSON.parse(userData);
    formData.user_id = userInfo.id;
    
    var AuthUserToken = localStorage.getItem("AuthUserToken")
    axios.defaults.headers.common['AuthUserToken'] = AuthUserToken;
    try {
        let data = await axios.post(process.env.REACT_APP_BACK_END+"/employees-store",
        {
            formData:formData
        });
        if(data.data.data.insertId > 0)
        {
            return {id:data.data.data.insertId, status:true};
        }else
        {
            return {status:false};
        }
        
    }catch(e) {
        return {status:false};
    }
    
    
}
export async function employeeDelete(id)
{
    var AuthUserToken = localStorage.getItem("AuthUserToken")
    axios.defaults.headers.common['AuthUserToken'] = AuthUserToken;

    try {
        let data = await axios.delete(process.env.REACT_APP_BACK_END+"/employees-delete/" + id);
        // console.log(data);
        if(data.status === 200)
        {
            return {status:true};
        }else
        {
            return {status:false};
        }
        
    }catch(e) {
        return {status:false};
    }
}

export async function employeeGet(id)
{
    // var AuthUserToken = localStorage.getItem("AuthUserToken")
    // axios.defaults.headers.common['AuthUserToken'] = AuthUserToken;

    try {
        let data = await axios.get(process.env.REACT_APP_BACK_END+"/employee-get/" + id);
        // console.log(data);
        if(data.status === 200)
        {
            return {data: data.data.data.data[0] ,status:true, emp_cv:data.data.data.emp_cv,emp_image:data.data.data.emp_image};
        }
        else
        {
            return {status:false};
        }
        
    }catch(e) {
        return {status:false};
    }
}

export async function updateEmployee(data) {
    try{
        let employee = await axios.put(process.env.REACT_APP_BACK_END+"/employeeUpdate/"+data.id, data);
        return {status:true, employee: employee}
    }catch(e){
        return {status:false, message:e}
    }
   
   
}