
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import { PrivateRoutes, PublicRoutes } from './routeConfig';
import { publicRoutes } from './routeList';
const Routes = () => {
    return (
        <Router>
            <Switch>
                {publicRoutes.map((route, i) =>{
                   return route.private?
                    <PrivateRoutes exact key={i}  path={route.url} component={route.component}/>
                    :
                    <PublicRoutes exact key={i} path={route.url} component={route.component} />
                   
                })}
            </Switch>
        </Router>
    );
};

export default Routes;