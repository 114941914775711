import React, {useEffect, useState} from 'react';
import { useFormik } from "formik";
import {allCategoris} from '../../../model/category/Category'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getBlogBySlug, blogEdit , cronBlogMeta } from '../../../model/blog/Blog'
import cogoToast from 'cogo-toast';
import {UploadToS3} from '../../../model/s3-upload/UploadS3'

import Resizer from 'react-image-file-resizer';
import Amplify, { Auth, Hub, API, graphqlOperation, Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';


const BlogEditComponent = (props) => {
    const [loader, setloader]=useState(false);
    const [categoris, setCategoris] = useState();
    const [blog, setBlog] = useState();
    const [description, setDescription] = useState();

    useEffect(async() =>{
        let blogBySlug = await getBlogBySlug(props.slug);
        setBlog(blogBySlug.data.data)
        setDescription(blogBySlug?.blog_description)
        let category = await allCategoris();
        setCategoris(category.data);
    },[])

    const validateForm = (values) => {
        const errors = {};
        
        if (!values.title) errors.title = "Blog title is required";
        // if (!values.blog_thumbnail) errors.blog_thumbnail = "Blog thumbnail is required";
        if (!values.category) errors.category = "Category is required";
        if (!values.reading_time) errors.reading_time = "Reading Time is required";
        // if (!values.published_date) errors.published_date = "Published Date is required";
        if (!values.short_description) errors.short_description = "Short Description is required";
        if (!values.description) errors.description = "Description is required";
        if (!values.tags) errors.tags = "Tags is required";
        if (!values.published_status) errors.published_status = "Published status is required";
        if (!values.blog_keyword) errors.blog_keyword = "Blog Keyword is required";
        if (!values.blog_meta_description) errors.blog_meta_description = "Blog Meta Description is required";
       
        return errors;
    };
    
    const submitForm = async (values,{resetForm}) => {
        values.description = description
        // return false;
        setloader(true);
         
        let response = await blogEdit(values);
        if(response.status)
        {
          resetForm();
          setloader(false);
          
          cogoToast.success("Successfully Updated");
  
        }else
        {

            cogoToast.error(response.err);
        }
        setloader(false);
    };


    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        // Create an object of formData
        const data = new FormData(event.target);

        setloader(true);
        let response = await blogEdit(data);
        if(response.status)
        {
          setloader(false);
          cogoToast.success("Successfully Updated");
          await cronBlogMeta();
  
        }else
        {
            cogoToast.error(response.err);
        }
        setloader(false);
    };


    const thumbUploadS3 =async(e)=>{
        // let response = await UploadToS3(e.target.files[0]);

        Resizer.imageFileResizer(e.target.files[0],
            575,
            600,
            'WEBP',
            100,
            0,
            async uri => {
              try {
                var storage = await Storage.put( uuidv4() + "_"+ e.target.files[0].name,
                  uri,
                  {
                    contentType: 'image/*'
                  }
                );
    
                if(storage.key){

                  let url = `https://doodlelatestbuckte102925-prod.s3.ap-southeast-1.amazonaws.com/public/${storage.key}`
                  document.getElementById("feature_image").value = url
                  cogoToast.success("Success !");
                  setloader(false);
                }else{
                    cogoToast.error("Failed !");
                }

              } catch (err) {

                cogoToast.error("Failed !");
              }
    
            },
            'file'
        )
    }
    
    const formik = useFormik({
        initialValues: {
            id: blog?.id,
            title: blog?.blog_title,
            blog_thumbnail: "",
            category: blog?.category_id,
            reading_time: blog?.reading_time,
            short_description: blog?.blog_short_description,
            description: blog?.blog_description,
            tags: blog?.tags, 
            published_status: blog?.status,
            blog_keyword: blog?.blog_keyword,
            blog_meta_description: blog?.blog_meta_description,
        },
        enableReinitialize: true,
        validateOnBlur: true,
        validate: validateForm,
        onSubmit: submitForm,
    });
    const onchangetextData =async(event, editor)=>{
      
            const data = editor.getData();

            console.log("data ====== " , data)
            document.getElementById("blog_description").value = data
            setDescription(data)   
    }
    const oncBlurtextData = async(event, editor)=>{
            console.log('Blur.',event.target, editor );
    }
    const onFocustextData = async(event, editor)=>{
            console.log( 'Focus.',event.target, editor );
    }
    return (
        <>
          <div className="row">
            <div className="col-xl-12">
            {/* Traffic sources */}
            <div className="card">
                <div className="card-header header-elements-inline">
                <h6 className="card-title">Edit Blog</h6>
                <div className="header-elements">
                    <div className="form-check form-check-right form-check-switchery form-check-switchery-sm">
                    
                    </div>
                </div>
                </div>
                <div className="card-body ">
                <form action="" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label> Blog Title</label>
                        <input type="text" className="form-control" placeholder="Blog Title"
                         id="title"
                         defaultValue={blog?.blog_title}
                         required
                         name="blog_title"
                        />
                    </div>

                    <div className="form-group">
                        <label> Blog Meta Keyword</label>
                        <input type="text" className="form-control" placeholder="Blog Meta keyword"
                         id="blog_keyword"
                         defaultValue={blog?.blog_keyword}
                         required
                         name="blog_keyword"
                        />
                    </div>

                    <div className="form-group">
                        <label> Blog Meta Description</label>
                        <input type="text" className="form-control" placeholder="Blog Meta keyword"
                         id="blog_meta_description"
                         defaultValue={blog?.blog_meta_description}
                         required
                         name="blog_meta_description"
                        />
                    </div>

                    <div className="form-group">
                        <label> Blog Thumbnail</label>
                        <input type="file" className="form-control"
                        id="blog_thumbnail"
                        onChange={(e)=>thumbUploadS3(e)}
                        
                        />
                        <img src={blog?.feature_image} width="14%" />
                    </div>
                    <input defaultValue={blog?.feature_image} id="feature_image" name="feature_image" type="hidden"  />
                    <input defaultValue={blog?.id} name="id" type="hidden" />
                    <div className="form-group">
                        <label> Category</label>
                        <select className="form-control"  
                        id="category"
                        name="category_id"
                        value={blog?.category_id}
                        onChange={ (e)=> setBlog({ ...blog, [e.target.name] : e.target.value} ) }
                        >
                         {categoris?.map((item,index)=>{
                             return(<option value={item.id} >{item.name}</option>)
                         })}    
                         </select>
                    </div>
                    <div className="form-group">
                        <label> Reading time</label>
                        <input type="text" className="form-control" placeholder="Reading time" name="reading_time"
                         id="reading_time"
                         defaultValue={blog?.reading_time}
                        />
                    </div>
                    <div className="form-group">
                        <label>Published Date</label>
                        <input type="date" className="form-control" placeholder="Published Date" name="published_date" defaultValue={blog?.published_date}
                         id="published_date"
                        />
                    </div>
                    
                    <div className="form-group">
                        <label> Blog Short Description</label>
                        <textarea type="text" className="form-control" 
                        name="blog_short_description"
                         defaultValue={blog?.blog_short_description}
                         id="short_description"
                        placeholder="Blog Short Description">
                        </textarea>
                        
                    </div>
                    {/* <div className="form-group">
                        <label> Blog Description</label>
                        <textarea type="text" className="form-control" 
                         value={formik.values.description}
                         onBlur={formik.handleBlur}
                         onChange={formik.handleChange}
                         id="description"
                        placeholder="Blog  Description">
                        </textarea>
                        {formik.touched.description && formik.errors.description && (
                          <label className="text-danger">
                              {formik.errors.description}
                          </label>
                      )}
                    </div> */}
                     <CKEditor
                            editor={ ClassicEditor }
                            // data={blog?.blog_description != "" || blog?.blog_description != null ? JSON.parse(blog?.blog_description) : ""}
                            data={blog?.blog_description}
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) => { onchangetextData(event, editor);  }  }
                            onBlur={ ( event, editor ) => oncBlurtextData(event, editor)}
                            onFocus={ ( event, editor ) =>onFocustextData (event, editor) }
                            upload={(event, editor)=>{
                                console.log( 'Uploaded', event , editor );
                            }}

                            config={{ckfinder: {
                             
                                uploadUrl: process.env.REACT_APP_BACK_END+'/s3-image-upload'
                            }}}

                            className="form-control"
                        />
                         {formik.touched.description && formik.errors.description && (
                          <label className="text-danger">
                              {formik.errors.description}
                          </label>
                      )}


                      <input id="blog_description" style={{ display: "none" }} name="blog_description" defaultValue={blog?.blog_description} type="text" />


                    <div className="form-group">
                        <label> Blog Tags</label>
                        <textarea type="text" className="form-control" 
                        name="tags"
                         defaultValue={blog?.tags}
                         required
                         placeholder="Blog  Tags">
                        </textarea>
                        
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Published Status</label>
                        <select className="form-control" name="status"
                        value={blog?.status}
                        onChange={ (e)=> setBlog({ ...blog, [e.target.name] : e.target.value} ) }
                        id="published_status"
                        >
                            <option value="1">Published</option>
                            <option value="0">Draft</option>
                        </select>
                    </div>
                   
                    
                    <div className="form-group">
                        {loader?
                             <input disabled type="submit" className="btn btn-primary" value="Save"/>
                        :
                        <input type="submit" className="btn btn-primary" value="Save"/>
                        }
                       
                    </div>

                </form>
                </div>
                <div className="chart position-relative" id="traffic-sources"><div className="d3-tip e" style={{display: 'none'}} /><svg width="920.90625" height={340}><g transform="translate(50,5)" width="920.90625" /></svg></div>
            </div>
            {/* /traffic sources */}
            </div>
            
        </div>
        </>
    );
};

export default BlogEditComponent;