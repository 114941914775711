import React from 'react';
import Layout from '../../../components/common/layout/Layout';
import EmployeeListComponent from '../../../components/dashboard/employee/EmployeeListComponent'

const EmployeeList = () => {
    return (
        <Layout>
            <EmployeeListComponent />
        </Layout>
    );
};

export default EmployeeList;