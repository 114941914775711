import React from 'react';
import Layout from '../../../components/common/layout/Layout';
import MeetingEditComponent from '../../../components/dashboard/meeting/MeetingEditComponent'
import { useParams } from "react-router-dom";

const MeetingEdit = () => {
    let { id } = useParams();
    return (
       <Layout>
           <MeetingEditComponent id={id}/>
       </Layout>
    );
};

export default MeetingEdit;