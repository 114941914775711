import React from 'react';
import Layout from '../../../components/common/layout/Layout';
import TalentListComponent from '../../../components/dashboard/ask-talent/TalentListComponent'

const TalentList = () => {
    return (
       <Layout>
           <TalentListComponent />
       </Layout>
    );
};

export default TalentList;