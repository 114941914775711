import React, {useEffect, useState} from 'react';
import { produce } from "immer";
import {allDepartments} from '../../../model/department/Department';
import {employeeStorge} from '../../../model/Employee/Employee'
import {UploadToS3} from '../../../model/s3-upload/UploadS3';
import { useFormik } from "formik";
import cogoToast from 'cogo-toast';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Resizer from 'react-image-file-resizer';

const EmployeeAddComponent = () => {
    const [departments, setDepartments]=useState();
    const [loader, setloader]=useState(false);
    const [refresh, setRefresh] = useState(false)
    // const [emp_image, setemp_image]=useState();

    useEffect(async ()=>{
        let allDepartmentsData =await allDepartments();
        console.log(allDepartmentsData, '------------------');
        setDepartments(allDepartmentsData);
    }, [])

    const [skillset, setSkillset] = useState([
        {  skill: "", skill_item:[
                    {technology:"", rating:""}
        ] }
      ]);

      const [skillsetdes, setSkillsetdes] = useState([
        {  skilldes: "" }
      ]);

      const [education, setEducation] = useState([
        {  edu: "" }
      ]);

      const [worksin, setWorksin] = useState([
        {  name: "", URL:"", Image:"" }
      ]);
    
    //   const [subskilset, setSubskilset] = useState(
    //             [
    //                     {technology:"", rating:""}
    //             ]);
      
    // console.log(skillset, '------------skillset')
    
    const validateForm = (values) => {
      const errors = {};
  
      if (!values.emp_name) errors.emp_name = "Employee Name is required";
      if (!values.salary) errors.salary = "Salary is required";
      if (!values.expert_developer) errors.expert_developer = "Required this field ";      
      if (!values.available_from) errors.available_from = "Required this field ";      
      if (!values.position) errors.position = "Required this field ";      
      if (!values.userAbout) errors.userAbout = "Required this field ";      
    //   if (!values.education) errors.education = "Required this field ";      
      if (!values.professional_training) errors.professional_training = "Required this field ";      
      if (!values.professional_certification) errors.professional_certification = "Required this field ";      
    //   if (!values.skillset) errors.skillset = "Required this field ";  
      if (!values.spaciality) errors.spaciality = "Required this field ";  
    //   if (!values.worked_in_project) errors.worked_in_project = "Required this field ";  
      if (!values.expert_in) errors.expert_in = "Required this field ";  
      if (!values.departments_id) errors.departments_id = "Required this field ";  
      if (!values.experience) errors.experience = "Required this field ";  
      // if (!values.responsible_for) errors.responsible_for = "Required this field ";  
      return errors;
  };
  
  const submitForm = async (values,{resetForm}) => {
      values.skillset = skillset;
      values.education = education;
      values.skillset_description = skillsetdes;
      values.worked_in_project = worksin;
      console.log("--------------------", values);
    //   return false;
    setloader(true);
      let response = await employeeStorge(values);
      if(response.status)
      {
        cogoToast.success("Data Successfully inserted!");
        resetForm();
        setloader(false);
        // window.location.reload();

      }else
      {
        setloader(false);
        cogoToast.error("Failed !"); 
      }
     console.log(response, '--------response');
    
  };
  
  const formik = useFormik({
      initialValues: {
        emp_name: "",
        salary: "",
        expert_developer:"",
        available_from:"",
        position:"",
        userAbout:"",
        professional_training:"",
        professional_certification:"",
        skillset:"",
        spaciality:"",
        worked_in_project:"",
        expert_in:"",
        departments_id:"",
        detail_experties:"",
        experience:"",
        responsible_for:"",
        emp_image:"",
        is_sla:"0",
        emp_img_base:"",
      },
      validateOnBlur: true,
      validate: validateForm,
      onSubmit: submitForm,
  });
  const uploadEmployeeImage =async(e)=>
  {
    //   console.log(e.target.files[0], '----------------e');
    setloader(true);
    await UploadToS3(e.target.files[0], (response) => {
    console.log("response-----" , response)
    console.log(response)


    if(response.key)
    {
          formik.values.emp_image = 'public/'+ response.key;
          axios.post( process.env.REACT_APP_BACK_END+"/base64convert", {
            imageUrl: 'https://doodlelatestbuckte102925-prod.s3.ap-southeast-1.amazonaws.com/public/'+ response.key ,
          })
          .then(function (response) {
            console.log( "img " ,  response.data);
            formik.values.emp_img_base = " ";
            
          })
          .catch(function (error) {
            console.log(error);
          });
        setloader(false);
    }else
    {
        formik.errors.emp_image = "Upload Failed !"
    }


    });


    

    // let name = uuidv4() + "_"+ e.target.files[0].name;
    //   Resizer.imageFileResizer(name,
    //     575,
    //     600,
    //     'WEBP',
    //     100,
    //     0,
    //     async uri => {
    //       try {
    //         var storage = await Storage.put( "/"+name,
    //           uri,
    //           {
    //             contentType: 'image/*'
    //           }
    //         );

            

    //         if(storage.key){
    //           console.log("storage" , storage)
    //           return storage
    //         }

           

    //       } catch (err) {

    //         console.log('Error uploading file: ', err);
    //       }

    //     },
    //     'file'
    //   )




    // if(response)
    // {

    //   console.log("jfhvf" , response)

    //       axios.post( process.env.REACT_APP_BACK_END+"/base64convert", {
    //         imageUrl: response.response.location,
    //       })
    //       .then(function (response) {
    //         console.log( "img " ,  response.data);
    //         formik.values.emp_img_base = response.data;
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       });


    //     formik.values.emp_image = response.response.key;
    //     setloader(false);
    // }else
    // {
    //     formik.errors.emp_image = "Upload Failed !"
    // }
    
  }

  const setSubItem2 = async(p, index)=>{
    //debugger;
    let skildata = skillset;
    skildata[index].skill_item.push({technology:"", rating:""});
    // skildata[index].test = 0;
     setSkillset(skildata)
     setRefresh(!refresh)
    
   
  }
  const setSubDataVal= async(data, index, item_index)=>{
    //   console.log(skillset, '----------technology', index, item_index,data)
    let skildatatechnology = skillset;
    skildatatechnology[index].skill_item[item_index].technology =data;
       console.log(skildatatechnology, '----------technology', index, item_index,data)
    //  setSkillset(skildatatechnology)
    await setStateValue(skildatatechnology);
  }
  const setSubDataValRating = async(val, index, item_index)=>{
      console.log(skillset, '-----------rating')
    let skildata = skillset;
    skildata[index].skill_item[item_index].rating = val;
    await setStateValue(skildata);
  }
  const setStateValue =async(data)=>{
        setSkillset(data)
  }
  const onlySkillSet = async(e, index)=>{
      let skildata = skillset;
      skildata[index].skill = e.target.value;
      await setStateValue(skildata);
  }
 
const setSkillsetdesToSate = async(e, index)=>{
    let desdata = skillsetdes;
    desdata[index].skilldes = e.target.value;
    setSkillsetdes(desdata);
}
const setEducationToState = async(e, index)=>{
    let educationDate = education;
    educationDate[index].edu = e.target.value;
    setEducation(educationDate);
}


console.log(458951521)
    return (
        <>
        <div className="row">
        <div className="col-xl-12">
          {/* Traffic sources */}
          <div className="card">
            <div className="card-header header-elements-inline">
              <h6 className="card-title">Add New Employee</h6>
              <div className="header-elements">
                <div className="form-check form-check-right form-check-switchery form-check-switchery-sm">
                
                </div>
              </div>
            </div>
            <div className="card-body ">
              <form action="" onSubmit={formik.handleSubmit}>
              <div className="col-md-12 row">
                <div className="col-md-6">
                  <div className="form-group">
                        <label>Employee Type</label>
                        <div>
                        <label>
                            <input 
                        type="radio" 
                        name="is_sla" 
                        value="0"
                        checked
                        value={formik.values.is_sla}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        />  General</label>

                         <br />
                        <label>
                            <input 
                        type="radio" 
                        name="is_sla"
                        value={formik.values.is_sla}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value="1" /> Offshore </label>
                        <br />
                        <label>
                            <input type="radio"
                        value={formik.values.is_sla}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name="is_sla" 
                        value="2" />  SLA </label>
                        </div>
                  </div>
                  <div className="form-group">
                      <label>Employee Name</label>
                      <input className="form-control" type="text" 
                      name="emp_name" placeholder="Employee Name"
                      id="emp_name"
                      value={formik.values.emp_name}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      />
                      {formik.touched.emp_name && formik.errors.emp_name && (
                          <label className="text-danger">
                              {formik.errors.emp_name}
                          </label>
                      )}
                  </div>
                  <div className="form-group">
                      <label>Employee Photo</label>
                      <input className="form-control" onChange={(e)=>uploadEmployeeImage(e)} type="file" id="emp_image" name="emp_image" />
                      {formik.touched.emp_image && formik.errors.emp_image && (
                          <label className="text-danger">
                              {formik.errors.emp_image}
                          </label>
                      )}
                      <p className="text-danger">Image size 300*340 is preferable </p>
                  </div>


                  {/* <div className="form-group">
                      <label>Employee CV</label>
                      <input className="form-control" onChange={(e)=>uploadEmployeeCV(e)} type="file" name="emp_cv" />
                      {formik.touched.emp_cv && formik.errors.emp_cv && (
                          <label className="text-danger">
                              {formik.errors.emp_cv}
                          </label>
                      )}
                  </div> */}
                  <div className="form-group">
                      <label>Salary</label>
                      <input className="form-control" type="number" name="salary"
                      id="salary"
                      value={formik.values.salary}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="Salary"/>
                      {formik.touched.salary && formik.errors.salary && (
                          <label className="text-danger">
                              {formik.errors.salary}
                          </label>
                      )}
                  </div>
                  <div className="form-group">
                      <label>Job Title</label>
                      <input className="form-control" type="text" name="expert_developer"
                      id="expert_developer"
                      value={formik.values.expert_developer}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="Title"/>
                      {formik.touched.expert_developer && formik.errors.expert_developer && (
                          <label className="text-danger">
                              {formik.errors.expert_developer}
                          </label>
                      )}
                  </div>
                  <div className="form-group">
                      <label>Available From</label>
                      <input className="form-control" type="date" name="available_from"
                      id="available_from"
                      value={formik.values.available_from}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="Expert Developer"/>
                      {formik.touched.available_from && formik.errors.available_from && (
                          <label className="text-danger">
                              {formik.errors.available_from}
                          </label>
                      )}
                  </div>
                  <div className="form-group">
                      <label>Designation</label>
                      <input className="form-control" type="text" name="position"
                      id="position"
                      value={formik.values.position}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="Position"/>
                      {formik.touched.position && formik.errors.position && (
                          <label className="text-danger">
                              {formik.errors.position}
                          </label>
                      )}
                  </div>
                  <div className="form-group">
                      <label>About</label>
                      <textarea className="form-control" name="userAbout"
                      id="userAbout"
                      value={formik.values.userAbout}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="Position">
                      </textarea>
                      {formik.touched.userAbout && formik.errors.userAbout && (
                          <label className="text-danger">
                              {formik.errors.userAbout}
                          </label>
                      )}
                  </div>
                  <div className="form-group">
                      <label>Education</label>
                      {education?.map((item, index)=>{
                          return (<>
                          <div className="form-group">
                            <input type="text" className="form-control"
                                onChange={e =>setEducationToState(e, index)}
                            />
                            </div>
                          
                          </>)
                      })}

                        <span className="btn btn-primary btn-icon "  
                                                    onClick={() => {
                                                        setEducation(currentArticle => [
                                                    ...currentArticle,
                                                    {
                                                        
                                                        edu: ""
                                                        
                                                    }
                                                    ]);
                                                    }}
                                                    ><i class="icon-plus-circle2 icon-2x"></i></span>
                    </div>
                  {/* <div className="form-group">
                    <label>Education</label>
                    <input className="form-control" type="text"name="education" 
                    id="education"
                    value={formik.values.education}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Education" />
                    {formik.touched.education && formik.errors.education && (
                          <label className="text-danger">
                              {formik.errors.education}
                          </label>
                      )}
                  </div> */}
                  <div className="form-group">
                    <label>Professional Certification</label>
                    <textarea className="form-control" name="professional_certification"
                    id="professional_certification"
                    value={formik.values.professional_certification}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Professional Certification. (Comma Separated for multiple inputs)">

                    </textarea>
                    {formik.touched.professional_certification && formik.errors.professional_certification && (
                          <label className="text-danger">
                              {formik.errors.professional_certification}
                          </label>
                      )} 
                    
                  </div>
                  <div className="form-group">
                    <label>Professional Training</label>
                    <textarea className="form-control" name="professional_training"
                    id="professional_training"
                    value={formik.values.professional_training}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Professional Training. (Comma Separated for multiple inputs)">
                    </textarea>
                    {formik.touched.professional_training && formik.errors.professional_training && (
                          <label className="text-danger">
                              {formik.errors.professional_training}
                          </label>
                      )}
                  </div>
                  
                </div>
                <div className="col-md-6">
                  
                  <div className="form-group">
                    <label>Skillset</label>

                    {skillset.map((p, index) => {
                            return ( 
                                <div className="form-group"  >
                                <input
                                    type="text"
                                    name="skill[]"
                                    placeholder="Skill Type"
                                    className="form-control"
                                    onChange={e => onlySkillSet(e, index)}
                                    
                                />
                               
                                {p.skill_item?.map((p_item, p_index)=>{
                                    return (<div className="form-group">
                                            <input className="form-contr"  
                                            onChange={e => setSubDataVal(e.target.value, index, p_index)}
                                            type="text" placeholder="Technology" />
                                            <input type="number" min="1" max="10" onChange={e => setSubDataValRating(e.target.value, index, p_index)} placeholder="Rating" />
                                    </div>)
                                })}

                                        <span className="btn btn-primary btn-icon small-btn-right"  
                                                    onClick={() =>setSubItem2(p, index)}
                                                    ><i class="icon-plus-circle2 icon-1x"></i></span>   

                                </div>
                                )
                            }) }
                            <span className="btn btn-primary btn-icon float-icon-image"  
                                                    onClick={() => {
                                                        setSkillset(currentArticle => [
                                                    ...currentArticle,
                                                    {
                                                        
                                                        skill: "",skill_item:[{technology:"",rating:""}]
                                                        
                                                    }
                                                    ]);
                                                    }}
                                                    ><i class="icon-plus-circle2 icon-2x"></i></span>
                    
                  </div>
                  <div className="form-group">
                      <label>Skillset description</label>
                      {skillsetdes?.map((item, index)=>{
                          return (<>
                          <div className="form-group">
                            <input type="text" className="form-control"
                                onChange={e =>setSkillsetdesToSate(e, index)}
                            />
                            </div>
                          
                          </>)
                      })}

                        <span className="btn btn-primary btn-icon "  
                          onClick={() => {
                              setSkillsetdes(currentArticle => [
                          ...currentArticle,
                          {
                              
                              skilldes: ""
                              
                          }
                          ]);
                          }}
                          ><i class="icon-plus-circle2 icon-2x"></i>
                        </span>
                  </div>
                  <div className="form-group">
                    <label>Speciality</label>
                    <textarea className="form-control" name="spaciality"
                    id="spaciality"
                    value={formik.values.spaciality}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Spaciality. (Comma Separated for multiple inputs)">

                    </textarea>
                    {formik.touched.spaciality && formik.errors.spaciality && (
                          <label className="text-danger">
                              {formik.errors.spaciality}
                          </label>
                      )}
                  </div>
                  {/* <div className="form-group"> */}
                    {/* <label>Worked in Project</label> */}

                    {/* <textarea className="form-control" name="worked_in_project"
                    id="worked_in_project"
                    value={formik.values.worked_in_project}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Worked in Project">
                    </textarea> */}
                    {/* {formik.touched.worked_in_project && formik.errors.worked_in_project && (
                          <label className="text-danger">
                              {formik.errors.worked_in_project}
                          </label>
                      )} */}
                      {/* {worksin?.map((item, index) => {
                          return(<div className="row form-group">
                          <div className="col-md-12">
                            <div className="col-md-4">
                                <input type="text" 
                                        onChange={(e)=>worksinarrayDataSave(e, index, 'name')}
                                        placeholder="Name" className="form-control" />
                            </div>
                            <div className="col-md-4">
                                <input type="file"
                                    onChange={(e)=>WorksinAttachUpload(e, index)}
                                    className="form-control" />
                            </div>
                            <div className="col-md-4">
                                <input type="text" placeholder="URL" 
                                    onChange={(e)=>worksinarrayDataSaveUrl(e, index, 'url')}
                                    className="form-control" />
                            </div>
                          </div>
                      </div>)
                      })} */}
                      
                      {/* <div className="row col-md-12 form-group"> 
                      <span className="btn btn-primary btn-icon"  
                                                    onClick={() => {
                                                        setWorksin(currentArticle => [
                                                    ...currentArticle,
                                                    {
                                                        
                                                        name: "", URL:"", Image:""
                                                        
                                                    }
                                                    ]);
                                                    }}
                                                    ><i class="icon-plus-circle2 icon-2x"></i></span>
                      </div>  */}
                  {/* </div> */}
                  <div className="form-group">
                    <label>Expertise Frontcard</label>
                     <textarea className="form-control"  name="expert_in"
                     id="expert_in"
                     value={formik.values.expert_in}
                     onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     placeholder="Expertise Frontcard. (Comma Separated. Ex: HTML,CSS)">
                    </textarea>
                    {formik.touched.expert_in && formik.errors.expert_in && (
                          <label className="text-danger">
                              {formik.errors.expert_in}
                          </label>
                      )}
                      <p className="text-danger"> Please write at least 4 Expertises with comma separated </p>
                  </div>
                  <div className="form-group">
                    <label>Department</label>
                    <select className="form-control" name="departments_id" 
                    id="departments_id"
                    value={formik.values.departments_id}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    >
                        <option value="">Select a Department</option>
                      {departments?.map((item,index) =>{
                        return <option value={item.id} >{item.name}</option>
                      })}
                    </select>
                    {formik.touched.departments_id && formik.errors.departments_id && (
                          <label className="text-danger">
                              {formik.errors.departments_id}
                          </label>
                      )}
                  </div>
                  <div className="form-group" style={{ display :"none" }}>
                    <label>About Frontcard</label>
                     <textarea className="form-control"  name="detail_experties"
                     id="detail_experties"
                     value={formik.values.detail_experties}
                     onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     placeholder="About Frontcard">
                    </textarea>
                  </div>
                  <div className="form-group">
                    <label> Experience </label>
                    <select className="form-control" name="experience" 
                    id="experience"
                    value={formik.values.experience}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    >
                        <option value="">Select a option</option>
                        {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].map((item)=>{
                          return(
                            <option value={item} >{item}</option>
                          )
                        })}
                        
                    </select>
                    {formik.touched.experience && formik.errors.experience && (
                          <label className="text-danger">
                              {formik.errors.experience}
                          </label>
                      )}
                  </div>
                  <div className="form-group" style={{ display:"none" }}>
                    <label>Responsible For</label>
                     <textarea className="form-control"   name="responsible_for" 
                     id="responsible_for"
                     value={formik.values.responsible_for}
                     onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     placeholder="Responsible For">
                    </textarea>
                    {formik.touched.responsible_for && formik.errors.responsible_for && (
                          <label className="text-danger">
                              {formik.errors.responsible_for}
                          </label>
                      )}
                  </div>
                </div>
                <div className="col-md-12">
                {loader == true?
                    <input  type="submit"  className="btn btn-primary" value="Save" />
                    :
                    <input  type="submit" className="btn btn-primary" value="Save" />
                }
                </div>
              </div>
              </form>
            </div>
          </div>
          {/* /traffic sources */}
        </div>
        
      </div>
        </>
    );
};

export default EmployeeAddComponent;