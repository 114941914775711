import axios from 'axios';
export async function meetingList(type='') {
    var AuthUserToken = localStorage.getItem("AuthUserToken")
    axios.defaults.headers.common['AuthUserToken'] = AuthUserToken;
    try {
        let data = await axios.get(process.env.REACT_APP_BACK_END+"/meeting-all?type="+type,
        {
           
        });
        if(data.data)
        {
            // console.log(data, '-----------data')
            return {id:data.data.data, status:true};
        }else
        {
            return {status:false};
        }
        
    }catch(e) {
        return {status:false};
    }
    
    
}
export async function meetingDelete(id) {
    var AuthUserToken = localStorage.getItem("AuthUserToken")
    axios.defaults.headers.common['AuthUserToken'] = AuthUserToken;
    try {
        let data = await axios.delete(process.env.REACT_APP_BACK_END+"/meeting-delete/" + id,
        {
           
        });
        if(data.data)
        {
            // console.log(data, '-----------data')
            return {id:data.data.data, status:true};
        }else
        {
            return {status:false};
        }
        
    }catch(e) {
        return {status:false};
    }
    
    
}
export async function meetingStatus(id, status, status_name) {
    var AuthUserToken = localStorage.getItem("AuthUserToken")
    axios.defaults.headers.common['AuthUserToken'] = AuthUserToken;
    try {
        let data = await axios.put(process.env.REACT_APP_BACK_END+"/meeting-status/" + id, {
            status: status,
            status_name: status_name,
        });
        if(data.data)
        {
            return {status:true};
        }else
        {
            return {status:false};
        }
        
    }catch(e) {
        return {status:false};
    }
    
    
}