import React from 'react';
import Layout from '../../../components/common/layout/Layout';
import TeamListComponent from '../../../components/dashboard/meeting/TeamListComponent'

const MeetingList = () => {
    return (
       <Layout>
           <TeamListComponent />
       </Layout>
    );
};

export default MeetingList;