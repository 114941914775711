import React from 'react';
import Layout from '../../../components/common/layout/Layout';
import BlogListComponent from '../../../components/dashboard/blog/BlogListComponent'

const BlogList = () => {
    return (
        <Layout>
            <BlogListComponent />
        </Layout>
    );
};

export default BlogList;