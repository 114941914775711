import React from 'react';
import Layout from '../../components/common/layout/Layout';
import DashboardComponent from '../../components/dashboard/Dashboard';
const Dashboard = () => {
    return (
        <Layout>
            <DashboardComponent />
        </Layout>
    );
};

export default Dashboard;