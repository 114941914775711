import React from 'react';
import { useFormik } from "formik";
import axios from 'axios';
import cogoToast from 'cogo-toast';
const Login = () => {

  const validateForm = (values) => {
    const errors = {};

    if (!values.email) errors.email = "Email is required";
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = "Invalid email address";
    }
    if (!values.password) errors.password = "Password is required";

    return errors;
};

const submitForm = async (values) => {
   try{
    let response = await axios.post(process.env.REACT_APP_BACK_END+'/admin-signIn', {values});
    if(response)
    {
      localStorage.setItem("userData", JSON.stringify(response.data.data));
      localStorage.setItem('AuthUserToken', response.data.token)
      // history.push("/Dashboard");
      window.location.href="/Dashboard"
    }
   }catch(e){
    cogoToast.error(e.message);
   }
   
};

const formik = useFormik({
    initialValues: {
        email: "",
        password: "",
        loggedIn: false,
    },
    validateOnBlur: true,
    validate: validateForm,
    onSubmit: submitForm,
});
    return (
        <>
            <div className="page-content">
        {/* Main content */}
        <div className="content-wrapper">
          {/* Content area */}
          <div className="content d-flex justify-content-center align-items-center pt-0">
            {/* Login card */}
            <form className="login-form" action="" onSubmit={formik.handleSubmit}>
              <div className="card mb-0">
                <div className="card-body">
                  <div className="text-center mb-3">
                    <i className="icon-people icon-2x text-warning-400 border-warning-400 border-3 rounded-round p-3 mb-3 mt-1" />
                    <h5 className="mb-0">Login to your account</h5>
                    <span className="d-block text-muted">Your credentials</span>
                    {formik.touched.sever && formik.errors.sever && (
                          <label className="text-danger">
                              {formik.errors.sever}
                          </label>
                      )}
                  </div>
                  <div className="form-group form-group-feedback form-group-feedback-left">
                    <input type="text" className="form-control" 
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Enter your username"
                    id="email"
                    />
                    <div className="form-control-feedback">
                      <i className="icon-user text-muted" />
                    </div>
                    {formik.touched.email && formik.errors.email && (
                          <label className="text-danger">
                              {formik.errors.email}
                          </label>
                      )}
                  </div>
                  <div className="form-group form-group-feedback form-group-feedback-left">
                    <input type="password" className="form-control"
                     
                     id="password"
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      placeholder="Enter your password"
                    
                    />
                    
                    <div className="form-control-feedback">
                      <i className="icon-lock2 text-muted" />
                    </div>
                    {formik.touched.password && formik.errors.password && (
                                            <label className="text-danger">
                                                {formik.errors.password}
                                            </label>
                                        )}
                  </div>
                 
                  <div className="form-group">
                    <button onClick={formik.handleSubmit} type="submit" className="btn btn-primary btn-block">Sign in <i className="icon-circle-right2 ml-2" /></button>
                  </div>
                </div>
              </div>
            </form>
            {/* /login card */}
          </div>
          {/* /content area */}
        </div>
        {/* /main content */}
      </div>
        </>
    );
};

export default Login;