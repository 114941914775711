import React, {useEffect, useState} from 'react';
import {allDepartments} from '../../../model/department/Department';
import { produce } from "immer";
import {employeeGet, updateEmployee} from '../../../model/Employee/Employee'
import {UploadToS3} from '../../../model/s3-upload/UploadS3';
import { useFormik } from "formik";
import cogoToast from 'cogo-toast';
import { ImageS3 } from '../../../model/s3Image/S3Image';
import axios from 'axios';

const EmployeeEditComponent = (props) => {
    const [departments, setDepartments]=useState();
    const [loader, setloader]=useState(false);
    const [employee, setEmployee] = useState([]);
    const [refresh, setRefresh] = useState(false)
    const [employee_image, setEmp_Image] = useState('')
    const [employee_imageBase, setEmp_ImageBase] = useState('')
    const [emp_cv, setEmp_cv] = useState()
    const [theskillset, settheskillset] = useState([])

    const [theskillsetForUpdate, setSkillsetForUpdate] = useState([])

    const [skillset, setSkillset] = useState([
        {  skill: "", skill_item:[
                    {technology:"" , rating:"" }
        ] }
      ]);
      

      const [worksin, setWorksin] = useState([
        {  name: "", URL:"", Image:"" }
      ]);
    
      const [subskilset, setSubskilset] = useState(
                [
                        {technology:"", rating:""}
                ]);

    const [education, setEducation] = useState([
        {  edu: "" }
    ]);

    const [skillsetdes, setSkillsetdes] = useState([
                    {  skilldes: "" }
                  ]);
      
  const setSubItem2 = async(p, index)=>{
    let skildata = skillset;
    skildata[index].skill_item.push({technology:"", rating:""});
    
     setSkillset(skildata)
     setRefresh(!refresh)
  }

    useEffect(async() =>{
        // debugger;
        let departmentData = await allDepartments();
        setDepartments(departmentData);
        let employee_data = await employeeGet(props.id);

        console.log("employee_data " , employee_data)
        setEmp_Image(employee_data.data.emp_image)
        // setEmp_ImageBase(employee_data.emp_img_base)
        // setEmp_cv(employee_data.emp_cv)
        setEmployee(employee_data.data);
        if(employee_data)
        {
            setSkillset(JSON.parse(employee_data.data.skillset))
            setSkillsetForUpdate(JSON.parse(employee_data.data.skillset))
            setSkillsetdes(JSON.parse(employee_data.data.skillset_description))
            setWorksin(JSON.parse(employee_data.data.worked_in_project))
            setEducation(JSON.parse(employee_data.data.education))
            let professional_certification_array= employee_data.data.professional_certification.replaceAll('[', '')
            let professional_certification_2= employee_data.data.professional_certification.replaceAll(']', '')
            let professional_certification_3= employee_data.data.professional_certification.replaceAll('"', '')
           
            // ImageS3(employee_data.data.emp_image);
            // let pro = JSON.parse(employee_data.data.professional_certification);
            // let datapro = pro.join(',')
            // console.log(datapro, '-------')
        }
        

    },[]);

    

    const validateForm = (values) => {
      const errors = {};
  
      if (!values.emp_name) errors.emp_name = "Employee Name is required";
      if (!values.salary) errors.salary = "Salary is required";
      if (!values.expert_developer) errors.expert_developer = "Required this field ";      
      if (!values.available_from) errors.available_from = "Required this field ";      
      if (!values.position) errors.position = "Required this field ";      
      if (!values.userAbout) errors.userAbout = "Required this field ";      
    //   if (!values.education) errors.education = "Required this field ";      
      if (!values.professional_training) errors.professional_training = "Required this field ";      
      if (!values.professional_certification) errors.professional_certification = "Required this field ";      
      if (!values.spaciality) errors.spaciality = "Required this field ";  
      if (!values.worked_in_project) errors.worked_in_project = "Required this field ";  
      if (!values.expert_in) errors.expert_in = "Required this field ";  
      if (!values.departments_id) errors.departments_id = "Required this field ";  
    //   if (!values.detail_experties) errors.detail_experties = "Required this field ";  
      if (!values.experience) errors.experience = "Required this field ";  
      return errors;
  };
  
  const submitForm = async (values,{resetForm}) => {

    console.log(values, '-----------values');
    values.skillset = skillset;
    values.education = education;
    values.skillset_description = skillsetdes;
    values.worked_in_project = worksin;
     
    //  return false;
    setloader(true);
      let response = await updateEmployee(values);
      if(response.status)
      {
        cogoToast.success("Data Successfully updated!");
        // resetForm();
        setloader(false);
        // window.location.reload();

      } else {
          setloader(false);
      }
  };

  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
  const formik = useFormik({
      initialValues: {
        id: employee?.id,
        emp_image: employee_image,
        emp_img_base: employee?.emp_img_base,
        emp_name: employee?.emp_name,
        salary: employee?.salary,
        expert_developer:employee?.expert_developer,
        available_from: formatDate(employee?.available_from),
        position:employee?.position,
        userAbout:employee?.userAbout,
        education:education,
        professional_training: employee?.professional_training != null && employee?.professional_training != "" ? JSON.parse(employee?.professional_training).join(',') : "",
        professional_certification: employee?.professional_certification != null && employee?.professional_certification != "" ? JSON.parse(employee?.professional_certification).join(',') : "",
        spaciality: employee?.spaciality != null && employee?.spaciality != "" ? JSON.parse(employee?.spaciality).join(',') : "",
        expert_in: employee?.expert_in != null && employee?.expert_in != "" ? JSON.parse(employee?.expert_in).join(',') : "",
        departments_id:employee?.departments_id,
        detail_experties:employee?.detail_experties,
        experience:employee?.experence,
        responsible_for:employee?.responsible_for,
        skillset:skillset,
        skillset_description:skillsetdes,
        worked_in_project:worksin,
        emp_cv: emp_cv,
        is_sla:employee?.is_sla
      },
      enableReinitialize: true,
      validateOnBlur: true,
      validate: validateForm,
      onSubmit: submitForm,
  });
  const uploadEmployeeImage =async(e)=>
  {
    //   console.log(e.target.files[0], '----------------e');
    setloader(true);
    await UploadToS3(e.target.files[0], (response) => {
    console.log("response-----" , response)
    console.log(response)


    if(response.key)
    {
        formik.values.emp_image = 'public/'+ response.key;

        setEmp_Image('public/'+ response.key)
        axios.post( process.env.REACT_APP_BACK_END+"/base64convert", {
        imageUrl: 'https://doodlelatestbuckte102925-prod.s3.ap-southeast-1.amazonaws.com/public/'+ response.key ,
        })
        .then(function (response) {
        
        // formik.values.emp_img_base = response.data;
        
        })
        .catch(function (error) {
        console.log(error);
        });
        setloader(false);
    }else
    {
        formik.errors.emp_image = "Upload Failed !"
    }


    });

    
  }
  const uploadEmployeeCV = async(e)=>{
    setloader(true);
    let response = await UploadToS3(e.target.files[0]);
    // console.log(response)
    if(response.status)
    {
        setEmp_cv(response.response.key);
        setloader(false)
    }
    else
    {
        formik.errors.emp_cv = "Upload Failed !"
    }
  }


  const setSkillsetdesToSate = async(e, index)=>{
      let desdata = skillsetdes;
      desdata[index].skilldes = e.target.value;
      setSkillsetdes(desdata);
      setRefresh(!refresh)
  }
  const setEducationToState = async(e, index)=>{
      let educationDate = education;
      educationDate[index].edu = e.target.value;
      setEducation(educationDate);
      setRefresh(!refresh)
  }

  

  setTimeout(function(){ 
    let x = Object.keys(theskillset)
    for (let index = 0; index < x.length; index++) {
        const element = x[index];
        let ob =  x[index]?.split("[")
        if(ob){
            if ( ob[0] == "skill") {
            
                let firstIndex = ob[1].match(/\d+/)[0]
                skillset[firstIndex].skill = theskillset[element]

            }

            if ( ob[0] == "technology") {

                let firstIndex = ob[1].match(/\d+/)[0]
                let secoundIndex = ob[2].match(/\d+/)[0]
                skillset[firstIndex].skill_item[secoundIndex].technology = theskillset[element]
                
            }

            if ( ob[0] == "rating") {

                let firstIndex = ob[1].match(/\d+/)[0]
                let secoundIndex = ob[2].match(/\d+/)[0]
                skillset[firstIndex].skill_item[secoundIndex].rating = theskillset[element]
                
            }


        }

    }

   }, 3000);

    return (
        <>
           <div className="row">
        <div className="col-xl-12">
          {/* Traffic sources */}
          <div className="card">
            <div className="card-header header-elements-inline">
              <h6 className="card-title">Edit Employee</h6>
              <div className="header-elements">
                <div className="form-check form-check-right form-check-switchery form-check-switchery-sm">
                
                </div>
              </div>
            </div>
            <div className="card-body ">
              <form action="" onSubmit={formik.handleSubmit}>
              <div className="col-md-12 row">
                <div className="col-md-6">
                  <div className="form-group">
                        <label>Employee Type </label>
                        <div>

                        <label>

                            {
                                employee?.is_sla == 0 && 

                                <input type="radio" 
                                defaultChecked
                                name="is_sla" 
                                id="is_sla"
                                value="0"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                />
                            }

                            {
                                employee?.is_sla != 0 && 

                                <input type="radio" 
                                name="is_sla" 
                                id="is_sla"
                                value="0"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                />
                            }
                            
                             General
                        
                        </label> 

                        <br />

                        <label>

                            {
                                employee?.is_sla == 1 && 

                                <input type="radio" 
                                defaultChecked
                                name="is_sla" 
                                id="is_sla"
                                value="1"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                />
                            }

                            {
                                employee?.is_sla != 1 && 

                                <input type="radio" 
                                name="is_sla" 
                                id="is_sla"
                                value="1"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                />
                            }
                            
                            Offshore
                        
                        </label>
                        <br />


                        <label>

                            {
                                employee?.is_sla == 2 && 

                                <input type="radio" 
                                defaultChecked
                                name="is_sla" 
                                id="is_sla"
                                value="2"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                />
                            }

                            {
                                employee?.is_sla != 2 && 

                                <input type="radio" 
                                name="is_sla" 
                                id="is_sla"
                                value="2"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                />
                            }
                            
                            SLA
                        
                        </label>
                        
                        </div>
                  </div>
                  <div className="form-group">
                      <label>Employee Name</label>
                      <input className="form-control" type="text" 
                      name="emp_name" placeholder="Employee Name"
                      id="emp_name"
                      value={formik.values.emp_name}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      />
                      {formik.touched.emp_name && formik.errors.emp_name && (
                          <label className="text-danger">
                              {formik.errors.emp_name}
                          </label>
                      )}
                  </div>
                  <div className="form-group">
                      <div className="imag-responsive"> 
                       {employee.emp_image&&<img src={'https://doodlelatestbuckte102925-prod.s3.ap-southeast-1.amazonaws.com/'+employee.emp_image} className="img-responsive" width="150px" height="auto" alt="" />}
                        
                      </div>
                      <label>Employee Photo</label>
                      <input className="form-control" onChange={(e)=>uploadEmployeeImage(e)} type="file" id="emp_image" name="emp_image" />
                      {formik.touched.emp_image && formik.errors.emp_image && (
                          <label className="text-danger">
                              {formik.errors.emp_image}
                          </label>
                      )}
                      <p className="text-danger">Image size 300*340 is preferable </p>
                  </div>


                  {/* <div className="form-group">
                      <label>Employee CV</label>
                      <input className="form-control" onChange={(e)=>uploadEmployeeCV(e)} type="file" name="emp_cv" />
                      {formik.touched.emp_cv && formik.errors.emp_cv && (
                          <label className="text-danger">
                              {formik.errors.emp_cv}
                          </label>
                      )}
                  </div> */}
                  <div className="form-group">
                      <label>Salary</label>
                      <input className="form-control" type="number" name="salary"
                      id="salary"
                      value={formik.values.salary}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="Salary"/>
                      {formik.touched.salary && formik.errors.salary && (
                          <label className="text-danger">
                              {formik.errors.salary}
                          </label>
                      )}
                  </div>
                  <div className="form-group">
                      <label>Job Title</label>
                      <input className="form-control" type="text" name="expert_developer"
                      id="expert_developer"
                      value={formik.values.expert_developer}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="Title"/>
                      {formik.touched.expert_developer && formik.errors.expert_developer && (
                          <label className="text-danger">
                              {formik.errors.expert_developer}
                          </label>
                      )}
                  </div>
                  <div className="form-group">
                      <label>Available From</label>
                      <input className="form-control" type="date" name="available_from"
                      id="available_from"
                      value={formik.values.available_from}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="Expert Developer"/>
                      {formik.touched.available_from && formik.errors.available_from && (
                          <label className="text-danger">
                              {formik.errors.available_from}
                          </label>
                      )}
                  </div>
                  <div className="form-group">
                      <label>Designation</label>
                      <input className="form-control" type="text" name="position"
                      id="position"
                      value={formik.values.position}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="Position"/>
                      {formik.touched.position && formik.errors.position && (
                          <label className="text-danger">
                              {formik.errors.position}
                          </label>
                      )}
                  </div>
                  <div className="form-group">
                      <label>About</label>
                      <textarea className="form-control" name="userAbout"
                      id="userAbout"
                      value={formik.values.userAbout}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="Position">
                      </textarea>
                      {formik.touched.userAbout && formik.errors.userAbout && (
                          <label className="text-danger">
                              {formik.errors.userAbout}
                          </label>
                      )}
                  </div>
                  {/* <div className="form-group">
                    <label>Education</label>
                    <input className="form-control" type="text"name="education" 
                    id="education"
                    value={formik.values.education}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Education" />
                    {formik.touched.education && formik.errors.education && (
                          <label className="text-danger">
                              {formik.errors.education}
                          </label>
                      )}
                  </div> */}
                  <div className="form-group">
                      <label>Education</label>
                      {education?.map((item, index)=>{
                          return (<>
                          <div className="form-group">
                            <input type="text" className="form-control"
                                onChange={e =>setEducationToState(e, index)}
                                value={item.edu}
                            />
                            </div>
                          
                          </>)
                      })}

                        <span className="btn btn-primary btn-icon "  
                                                    onClick={() => {
                                                        setEducation(currentArticle => [
                                                    ...currentArticle,
                                                    {
                                                        
                                                        edu: ""
                                                        
                                                    }
                                                    ]);
                                                    }}
                                                    ><i class="icon-plus-circle2 icon-2x"></i></span>
                    </div>
                  <div className="form-group">
                    <label>Professional Certification</label>
                    <textarea className="form-control" name="professional_certification"
                    id="professional_certification"
                    value={formik.values.professional_certification}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Professional Certification. (Comma Separated for multiple inputs)">

                    </textarea>
                    {formik.touched.professional_certification && formik.errors.professional_certification && (
                          <label className="text-danger">
                              {formik.errors.professional_certification}
                          </label>
                      )}
                    <p className="text-danger"> (Comma Separated for multiple inputs) </p>
                  </div>
                  <div className="form-group">
                    <label>Professional Training</label>
                    <textarea className="form-control" name="professional_training"
                    id="professional_training"
                    value={formik.values.professional_training}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Professional Training. (Comma Separated for multiple inputs)">
                    </textarea>
                    {formik.touched.professional_training && formik.errors.professional_training && (
                          <label className="text-danger">
                              {formik.errors.professional_training}
                          </label>
                      )}
                    <p className="text-danger"> (Comma Separated for multiple inputs) </p>
                  </div>
                  
                </div>
                <div className="col-md-6">
                  
                  <div className="form-group">
                    <label>Skillset</label>

                    {skillset?.map((p, index) => {
                        
                            return ( 
                                <div className="form-group"  >
                                <input
                                    type="text"
                                    name={"skill"+"["+index+"]"}
                                    placeholder="Skill Type"
                                    className="form-control"
                                    // onChange={e => {
                                    //     const skill = e.target.value;
                                    //     setSkillset(currentArticle =>
                                    //     produce(currentArticle, v => {
                                    //         v[index].skill = skill;
                                    //     })
                                    //     );
                                    // }}

                                    onChange={ (e)=> { settheskillset( { ...theskillset, [e.target.name] : e.target.value} ); }  }
                                    defaultValue={p.skill}
                                />
                               
                                {p.skill_item?.map((p_item, p_index)=>{
                                    return (<div className="form-group">
                                            <input className="form-contr" type="text" placeholder="Technology" 
                                            onChange={ (e)=> { settheskillset({ ...theskillset, [e.target.name] : e.target.value} ); }  }
                                            
                                            defaultValue={p_item.technology} name={"technology"+"["+index+"]["+ p_index + "]" }
                                            />
                                            <input type="number" min="1" max="10" placeholder="Rating" name={"rating"+"["+index+"]["+ p_index + "]"} onChange={ (e)=> { settheskillset({ ...theskillset, [e.target.name] : e.target.value} ); }  } defaultValue={p_item.rating} />
                                    </div>)
                                })}

                                        <span className="btn btn-primary"  
                                                    onClick={() =>setSubItem2(p, index)}
                                                    >Add</span>   

                            {/* {subskilset?.map((p_item, p_index)=>{
                                    return (<div className="form-group">
                                            <input className="form-contr" type="text" placeholder="Technology" />
                                            <input type="text" placeholder="Rating" />
                                    </div>)
                                })}

                                        <span className="btn btn-primary"  
                                                    onClick={() =>setSubItemto(p, index)}
                                                    >Add</span>     */}

                                </div>
                                )
                            }) }
                            <span className="btn btn-primary"  
                                                    onClick={() => {
                                                        setSkillset(currentArticle => [
                                                    ...currentArticle,
                                                    {
                                                        
                                                        skill: "",skill_item:[{technology:""}]
                                                        
                                                    }
                                                    ]);
                                                    }}
                                                    >Add New Row</span>
                  </div>
                  <div className="form-group">
                      <label>Skillset description</label>
                      {skillsetdes?.map((item, index)=>{
                          return (<>
                          <div className="form-group">
                            <input type="text" className="form-control"
                                onChange={e =>setSkillsetdesToSate(e, index)}
                                value={item.skilldes}
                            />
                            </div>
                          
                          </>)
                      })}

                        <span className="btn btn-primary"  
                                                    onClick={() => {
                                                        setSkillsetdes(currentArticle => [
                                                    ...currentArticle,
                                                    {
                                                        
                                                        skilldes: ""
                                                        
                                                    }
                                                    ]);
                                                    }}
                                                    >Add New Row</span>
                  </div>
                  <div className="form-group">
                    <label>Speciality</label>
                    <textarea className="form-control" name="spaciality"
                    id="spaciality"
                    value={formik.values.spaciality}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Spaciality. (Comma Separated for multiple inputs)">

                    </textarea>
                    {formik.touched.spaciality && formik.errors.spaciality && (
                          <label className="text-danger">
                              {formik.errors.spaciality}
                          </label>
                      )}
                    <p className="text-danger"> (Comma Separated for multiple inputs) </p>
                  </div>
                  
                  {/* <div className="form-group">
                    <label>Worked in Project</label>
                      {worksin?.map((item, index) => {
                          return(<div className="row form-group">
                          <div className="col-md-4">
                              <input type="text" 
                              onChange={(e)=>worksinarrayDataSave(e, index, 'name')}
                              placeholder="Name" className="form-control"
                              value={item.name}
                              />
                          </div>
                          <div className="col-md-4">
                              <input type="file" placeholder="Name"
                               onChange={(e)=>WorksinAttachUpload(e, index)}
                              className="form-control" />
                          </div>
                          <div className="col-md-4">
                              <input type="text" placeholder="URL" 
                              onChange={(e)=>worksinarrayDataSaveUrl(e, index, 'url')}
                              className="form-control" value={item.url} />
                          </div>
                      </div>)
                      })}
                      
                      <div className="row form-group"> 
                      <span className="btn btn-primary"  
                                                    onClick={() => {
                                                        setWorksin(currentArticle => [
                                                    ...currentArticle,
                                                    {
                                                        
                                                        name: "", URL:"", Image:"" 
                                                        
                                                    }
                                                    ]);
                                                    }}
                                                    >Add New Row</span>
                      </div> 
                  </div> */}
                  <div className="form-group">
                    <label>Expertise Frontcard</label>
                     <textarea className="form-control"  name="expert_in"
                     id="expert_in"
                     value={formik.values.expert_in}
                     onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     placeholder="Expertise Frontcard">
                    </textarea>
                    {formik.touched.expert_in && formik.errors.expert_in && (
                          <label className="text-danger">
                              {formik.errors.expert_in}
                          </label>
                      )}
                      <p className="text-danger"> Please write at least 4 Expertises with comma separated </p>
                  </div>
                  <div className="form-group">
                    <label> Department</label>
                    <select className="form-control" name="departments_id" 
                    id="departments_id"
                    value={formik.values.departments_id}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    >
                        <option value="">Select a Department</option>
                      {departments?.map((item,index) =>{
                        return <option value={item.id} >{item.name}</option>
                      })}
                    </select>
                    {formik.touched.departments_id && formik.errors.departments_id && (
                          <label className="text-danger">
                              {formik.errors.departments_id}
                          </label>
                      )}
                  </div>
                  <div className="form-group" style={{ display:"none" }}>
                    <label>About Frontcard</label>
                     <textarea className="form-control"  name="detail_experties"
                     id="detail_experties"
                     value={formik.values.detail_experties}
                     onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     placeholder="About Frontcard">
                    </textarea>
                    {formik.touched.detail_experties && formik.errors.detail_experties && (
                          <label className="text-danger">
                              {formik.errors.detail_experties}
                          </label>
                      )}
                  </div>
                  <div className="form-group">
                    <label> Experience </label>
                    <select 
                    className="form-control" 
                    name="experience" 
                    id="experience"
                    value={formik.values.experience}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    >
                        <option value="">Select a option</option>
                        {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].map((item)=>{
                          return(
                            <option value={item} >{item}</option>
                          )
                        })}
                        
                    </select>
                    {formik.touched.experience && formik.errors.experience && (
                          <label className="text-danger">
                              {formik.errors.experience}
                          </label>
                      )}
                  </div>
                  <div className="form-group" style={{ display:"none" }}>
                    <label>Responsible For</label>
                     <textarea className="form-control"   name="responsible_for" 
                     id="responsible_for"
                     value={formik.values.responsible_for}
                     onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     placeholder="Responsible For">
                    </textarea>
                    {formik.touched.responsible_for && formik.errors.responsible_for && (
                          <label className="text-danger">
                              {formik.errors.responsible_for}
                          </label>
                      )}
                  </div>
                </div>
                <div className="col-md-12">
                {loader == true?
                    <input  type="submit"  className="btn btn-primary" value="Updating..." />
                    :
                    <input  type="submit" className="btn btn-primary" value="Update" />
                }
                  {/* <input  type="submit" className="btn btn-primary" value="Save" /> */}
                </div>
              </div>
              </form>
            </div>
          </div>
          {/* /traffic sources */}
        </div>
        
      </div>
        </>
    );
};

export default EmployeeEditComponent;