import React,{useEffect, useState} from 'react';
import {meetingList, meetingDelete, meetingStatus} from '../../../model/meeting/Meeting'
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Modal } from 'react-bootstrap'
import cogoToast from 'cogo-toast';
import deleteImg from '../../../image/delete.png'
import eyeImg from '../../../image/eye.png'

const MeetingListComponent = () => {
    const [meetingLists, setMeetingLists] = useState();
    const [modalData, setmodalData] = useState([]);
    const [modalStatusData, setmodalStatusData] = useState([]);
    const [modalStatusMeetingData, setmodalStatusMeetingData] = useState([]);
    const [memberModalShow, setMemberModalShow] = useState(false);
    const [statusModalShow, setStatusModalShow] = useState(false);
    const [statusMeetingModalShow, setStatusMeetingModalShow] = useState(false);

    const handleClose = () => setMemberModalShow(false);
    const handleStatusClose = () => setStatusModalShow(false);
    const handleStatusMeetingClose = () => setStatusMeetingModalShow(false);

    function findUserData(user_id) {
        axios.get(process.env.REACT_APP_BACK_END+"/customers/" + user_id).then(res => {
            setmodalData(res.data);
            setMemberModalShow(true);
        }).catch(function (error) {
            cogoToast.error(error.response.data.message , { position: 'top-right' } );
        });
    }

    function findStatusData(meeting_id) {
        axios.get(process.env.REACT_APP_BACK_END+"/meeting-get/" + meeting_id).then(res => {
            setmodalStatusData(res.data.data);
            setStatusModalShow(true);
        }).catch(function (error) {
            cogoToast.error(error.response.data.message , { position: 'top-right' } );
        });
    }

    function findStatusMeetingData(meeting_id) {
        axios.get(process.env.REACT_APP_BACK_END+"/meeting-get/" + meeting_id).then(res => {
            setmodalStatusMeetingData(res.data.data);
            setStatusMeetingModalShow(true);
        }).catch(function (error) {
            cogoToast.error(error.response.data.message , { position: 'top-right' } );
        });
    }
    
    const columns = [
        {
            name: 'Name',
            cell: row =>
            <>
                <a onClick={() => findUserData(row.user_id)} style={{ color:"white" , background :"#9836e3" , padding : "3px" , display : "inline-block" }}>{row.user_name}</a>
            </>
        },
        {
            name: 'Company',
            selector: row => row.compant_name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.company_email,
        },

        {
            name: 'Team',
            selector: row => row.team_name,
        },
        // {
        //     name: 'Address',
        //     selector: row => row.address,
        // },
        // {
        //     name: 'Phone',
        //     selector: row => row.phone,
        // },
        // {
        //     name: 'Website',
        //     selector: row => row.website,
        // },
        {
            name: 'Meeting Starting Date',
            selector: row => row.starting_date,
            sortable: true,
        },
        {
            name: 'Meeting Status',
            sortable: true,
            selector: row => 
            {
                if(row.meeting_status == 0) {
                     return  'Scheduled'
                } else if(row.meeting_status == 1) {
                    return 'Postponded'
                } else if(row.meeting_status == 2) {
                    return  'Cancelled'
                } else if(row.meeting_status == 3) {
                    return 'Pending'
                }
            }
        },
        {
            name: 'Team Status',
            selector: row => 
            {
                if(row.status == 1) {
                     return (
                        <a style={{ color:"white" , background :"#a44caf" , padding : "5px" }}onClick={ () => findStatusData(row.id) } >scheduled</a>
                     ) 
                } else if(row.status == 2) {
                    return <a style={{ color:"white" , background :"#a44caf" , padding : "5px" }}onClick={ () => findStatusData(row.id) } >Searching</a>
                } else if(row.status == 3) {
                    return  <a style={{ color:"white" , background :"#a44caf" , padding : "5px" }}onClick={ () => findStatusData(row.id) } >Hiring Done</a>
                } else if(row.status == 4) {
                    return  <a style={{ color:"white" , background :"#a44caf" , padding : "5px" }}onClick={ () => findStatusData(row.id) } >Cancelled</a>
                } else {
                    return <a style={{ color:"white" , background :"#a44caf" , padding : "5px" }}onClick={ () => findStatusData(row.id) } >Pending</a>
                }
            }
        },
        {
            name: "Action",
            cell: row =>

            <div className='row'>
                <a  onClick={() => {if(window.confirm('Are you sure to Delete this?')){DeleteMeeting(row.id)};}} ><img src={deleteImg} width="12px" /> </a>
                <a href={"/Meeting-List-details/"+row.team_id} > <img src={eyeImg} width="12px" /> </a>
                <a style={{ color:"white" , background :"green" , padding : "5px" }} onClick={ () => findStatusMeetingData(row.id) } > Meeting </a>
            </div>
        }
    ]

    const DeleteMeeting = async(id) => {
        let response = await meetingDelete(id);
        if(response.status)
        {
            cogoToast.success("Deleted Successfully!");
            let mailingList_data = await meetingList(); 
            setMeetingLists(mailingList_data.id);
        }
    }

    const StatusMeeting = async(id, status, status_name) => {
        let response = await meetingStatus(id, status, status_name);
        if(response.status)
        {
            cogoToast.success("Status Changed Successfully!");
            let mailingList_data = await meetingList(); 
            setMeetingLists(mailingList_data.id);
            handleStatusClose(false);
            handleStatusMeetingClose(false);
        }
    }

    useEffect(async()=>{
        let data = await meetingList();
        setMeetingLists(data.id);
    },[]);
    return (
        <>
          <>
            <div className="row">
                <div className="col-xl-12">
                {/* Traffic sources */}
                <div className="card">
                    <div className="card-header header-elements-inline">
                    <h6 className="card-title">Meeting List</h6>
                    <div className="header-elements">
                        <div className="form-check form-check-right form-check-switchery form-check-switchery-sm">
                        
                        </div>
                    </div>
                    </div>
                    <div className="card-body ">
                        <div className="table-responsive">
                            <DataTable
                                columns={columns}
                                data={meetingLists}
                                pagination
                                responsive
                            />
                        </div>
                        
                    </div>
                </div>
                {/* /traffic sources */}
                </div>
                
            </div>   
            <Modal show={memberModalShow} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                <Modal.Title>User Details</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6">
                                <p><b>Name:</b> {modalData.contact_person_name}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Phone:</b> {modalData.contact_person_phone}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Email:</b> {modalData.contact_person_email}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Website:</b> {modalData.contact_person_website}</p>
                            </div>
                            <div className="col-md-12">
                                <p><b>Address:</b> {modalData.contact_person_address}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Company Name:</b> {modalData.name}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Company Phone:</b> {modalData.phone}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Company Email:</b> {modalData.email}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Company Website:</b> {modalData.website}</p>
                            </div>
                            <div className="col-md-12">
                                <p><b>Company Address:</b> {modalData.address}</p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={statusModalShow} onHide={handleStatusClose} size="md" aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Header closeButton>
                    <Modal.Title>Change Team Status</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                            <label>Change Team Status</label>
                            <select className="form-control" onChange={ (e)=>StatusMeeting(modalStatusData.id, e.target.value, "status") } defaultValue={modalStatusData.status}>
                                <option value="0">Pending</option>
                                <option value="1">Meeting scheduled</option>
                                <option value="2">Searching</option>
                                <option value="3">Hiring completed</option>
                                <option value="4">Cancelled</option>
                            </select>
                        </Modal.Body>
                    </Modal>

                <Modal show={statusMeetingModalShow} onHide={handleStatusMeetingClose} size="md" aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Header closeButton>
                    <Modal.Title>Change Meeting Status</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                            <label>Change Meeting Status</label>
                            <select className="form-control" onChange={ (e)=>StatusMeeting(modalStatusMeetingData.id, e.target.value, "meeting_status") } defaultValue={modalStatusMeetingData.meeting_status}>
                                <option value="0">Meeting Scheduled</option>
                                <option value="1">Meeting Postponded</option>
                                <option value="2">Meeting Cancelled</option>
                                <option value="3">Meeting Pending</option>
                            </select>
                        </Modal.Body>
                    </Modal>
        </>  
        </>
    );
};

export default MeetingListComponent;