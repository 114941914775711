import React from 'react';
import Layout from '../../../components/common/layout/Layout';
import { useParams } from "react-router-dom";
import BlogEditComponent from '../../../components/dashboard/blog/BlogEditComponent'

const BlogEdit = () => {
    let { slug } = useParams();
    return (
        <Layout>
            <BlogEditComponent slug={slug}/>
        </Layout>
    );
};

export default BlogEdit;