import React,{useEffect, useState} from 'react';
import {askTalentList,askTalentDelete, updateTalent} from '../../../model/ask-talent/AskTalent'
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Modal } from 'react-bootstrap'
import cogoToast from 'cogo-toast';
import deleteImg from '../../../image/delete.png'
import eyeImg from '../../../image/eye.png'
import editImg from '../../../image/edit.png'

const TalentListComponent = () => {
    const [asktalents, setAskTalents] = useState();
    const [modalData, setmodalData] = useState([]);
    const [show, setShow] = useState(false);
    const [statusModalShow, setStatusModalShow] = useState(false);
    const [modalStatusData, setmodalStatusData] = useState(0);
    const [modelID, setmodelID] = useState(0);
    const handleStatusClose = () => setStatusModalShow(false);
    const handleClose = () => setShow(false);

    const StatusMeeting = async(id, status) => {
        console.log(id , status)
        let values= {}
        values.id = id
        values.status = status
        let response = await updateTalent(values);
        if(response.status)
        {
            let listData = await askTalentList();
            setAskTalents(listData.response);
            cogoToast.success("Data Successfully Updated!");
            setStatusModalShow(false)
        }else
        {
            cogoToast.error("Failed !"); 
        }

    }



    function findUserData(user_id) {
        axios.get(process.env.REACT_APP_BACK_END+"/customers/" + user_id).then(res => {
            setmodalData(res.data);
            setShow(true);
        }).catch(function (error) {
            cogoToast.error(error.response.data.message , { position: 'top-right' } );
        });
    }

    const columns = [
        {
            name: 'User',
            cell: row =>
            <>
                <a style={{ color:"white" , background :"#4caf50" , padding : "3px" , display : "inline-block" }} onClick={() => findUserData(row.user_id)}>{row.user_name}</a> 
            </>
        },
        {
            name: 'Talent/Experence',
            selector: row => 
            <>Total :{row.total_talent}/Year: {row.required_experience}</>
        },
        {
            name: 'Designation',
            selector: row => row.designation_name,
        },
        {
            name: 'Skill',
            selector: row => row?.required_skill != "" && row?.required_skill != null ? JSON.parse(row?.required_skill).join(",") : "",
        },
        {
            name: 'Date',
            sortable: true,
            selector: row => formatDate(row.created_at)
        },

        {
            name: 'Status',
            sortable: true,
            selector: row => 
            {
                if(row.status == 0) {
                     return  'Pending'
                } else if(row.status == 1) {
                    return 'Meeting scheduled'
                } else if(row.status == 2) {
                    return  'Searching'
                } else if(row.status == 3) {
                    return 'Hiring completed'
                }else{
                    return 'Cancelled'
                }
            }
        },

        {
            name: "Action",
            cell: row =>
            
            <div className='row'>
                <a onClick={ () => { setmodalData(row); setShow(true);  }  } ><img src={eyeImg} width="12px" /></a>
                <a onClick={ () => {setStatusModalShow(true); setmodelID(row.id);  setmodalStatusData(row.status); }  } ><img src={editImg} width="12px" /></a> 
                <a onClick={() => {if(window.confirm('Are you sure to Delete this?')){DeleteAskTalent(row.id)};}}><img src={deleteImg} width="12px" /></a>
            </div>
        }
    ]


  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

    useEffect(async() =>{
        let listData = await askTalentList();
        setAskTalents(listData.response);
    },[]);

    const DeleteAskTalent = async(id) => {
        let response = await askTalentDelete(id);
        if(response.status) {
            cogoToast.success('Deleted Successful!');
            let listData = await askTalentList();
            setAskTalents(listData.response);
        }
    }
    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                {/* Traffic sources */}
                <div className="card">
                    <div className="card-header header-elements-inline">
                    <h6 className="card-title">Ask Talents</h6>
                    <div className="header-elements">
                        <div className="form-check form-check-right form-check-switchery form-check-switchery-sm">
                        
                        </div>
                    </div>
                    </div>
                    <div className="card-body ">
                        <DataTable
                            columns={columns}
                            data={asktalents}
                            pagination
                            responsive
                        />
                    </div>
                    <div className="chart position-relative" id="traffic-sources"><div className="d3-tip e" style={{display: 'none'}} /><svg width="920.90625" height={340}><g transform="translate(50,5)" width="920.90625" /></svg></div>
                </div>
                {/* /traffic sources */}
                </div>
                
            </div>   

                <Modal show={statusModalShow} onHide={handleStatusClose} size="md" aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Header closeButton>
                    <Modal.Title>Change Talent Status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>Change Talent Status</label>
                        <select className="form-control" onChange={ (e)=>StatusMeeting(modelID, e.target.value) } defaultValue={modalStatusData}>
                            <option value="0">Pending</option>
                            <option value="1">Meeting scheduled</option>
                            <option value="2">Searching</option>
                            <option value="3">Hiring completed</option>
                            <option value="4">Cancelled</option>
                        </select>
                    </Modal.Body>
                </Modal>


                <Modal show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                <Modal.Title>Ask Talent Details</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6">
                                <p><b>User:</b> {modalData.user_name}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Department:</b> {modalData.department_name}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Designation :</b> {modalData.designation_name}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Total Talent:</b> {modalData.total_talent}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Certification:</b> {modalData.certification}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Required Skill:</b> { modalData?.required_skill ? JSON.parse(modalData?.required_skill).join(",") : "" }</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Required Expirencee:</b> {modalData.required_experience} Year</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Date:</b> {  formatDate(modalData.created_at) }</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Status:</b> {modalData.status == 0 ? "Pending" :  <> {modalData.status == 1 ?  "Meeting scheduled" : <> { modalData.status == 2 ? "Searching" : <> {modalData.status == 3 ? "Hiring completed" : "Cancelled" } </> } </> } </>     }</p>
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>

        </>
    );
};

export default TalentListComponent;