import React,{useEffect, useState} from 'react';
import {all_designations} from '../../../model/blog/Blog'
import DataTable from 'react-data-table-component';
const CustomerListComponent = () => {
    const [blogs, setBlogs] = useState();

    useEffect(async() =>{
        let blogList = await all_designations();
        setBlogs(blogList);
    },[]);

    const columns = [
        {
            name: 'Department',
            cell: row => row.departments_name
        },
        {
            name: 'Designation',
            selector: row => row.name,
        },
        {
            name: 'Experence',
            selector: row => row.year_of_experience,
        },
        {
            name: 'Salary',
            selector: row => <> { row.min_salary  } - { row.max_salary  } </> ,
        }
        // {
        //     name: "Action",
        //     cell: row =>

        //     <>
        //         <a href={"/edit-designations/"+row.id} className="btn btn-primary">Edit</a>
        //     </>
        // }
    ]
    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                {/* Traffic sources */}
                <div className="card">
                    <div className="card-header header-elements-inline">
                    <h6 className="card-title">Blogs List</h6>
                    <div className="header-elements">
                        <div className="form-check form-check-right form-check-switchery form-check-switchery-sm">
                        
                        </div>
                    </div>
                    </div>
                    <div className="card-body ">
                        <DataTable
                        columns={columns}
                        data={blogs}
                        pagination
                    />
                    </div>
                </div>
                </div>
                
            </div>   
        </>
    );
};

export default CustomerListComponent;