import axios from 'axios';

export async function create(data)
{
 try {

let response = await axios.post(process.env.REACT_APP_BACK_END+'/customers',data);
    return {response: response.data, status:true};
 }catch(e) {
    return {error: e, status:false};
 }
    
} 
export async function list(){
let list = await axios.get(process.env.REACT_APP_BACK_END+"/customers");    
return list.data;
}
export async function deleteCustomerByID(id)
{
    let response = await axios.delete(process.env.REACT_APP_BACK_END+"/customers/"+id);  
    console.log(response)
}
export async function singleCustomer(id){
    let singlecustomer = await axios.get(process.env.REACT_APP_BACK_END+"/customers/"+id);   
    return singlecustomer.data;
}
export async function updateCustomer(data) {
    try{
        let customer = await axios.put(process.env.REACT_APP_BACK_END+"/customers/"+data.id,data);
        return {status:true, customer: customer}
    }catch(e){
        return {status:false, message:e}
    }
}


export async function resetCredintial(id , callback )
{
    let response = await axios.get(process.env.REACT_APP_BACK_END+"/resetCredintial/"+id);  
    callback(response)
}