import React from 'react';
import { useParams } from "react-router-dom";
import Layout from '../../../components/common/layout/Layout';
import TalentEditComponent from '../../../components/dashboard/ask-talent/TalentEditComponent'
const TalentEdit = () => {
    let { id } = useParams();
    return (
       <Layout>
           <TalentEditComponent id={id} />
       </Layout>
    );
};

export default TalentEdit;