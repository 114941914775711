import React,{useEffect, useState} from 'react';
import {list} from '../../../model/blog/Blog'
import DataTable from 'react-data-table-component';
const CustomerListComponent = () => {
    const [blogs, setBlogs] = useState();

    useEffect(async() =>{
        let blogList = await list();
        setBlogs(blogList);
    },[]);

    const columns = [
        {
            name: 'Title',
            cell: row => row.blog_title
        },
        {
            name: 'Category',
            selector: row => row.category_name,
        },
        {
            name: 'Short Description',
            selector: row => row.blog_short_description,
        },
        {
            name: 'Tags',
            selector: row => row.tags,
        },
        {
            name: "Action",
            cell: row =>

            <>
                <a href={"/edit-blog/"+row.slug} className="btn btn-primary">Edit</a>
            </>
        }
    ]
    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                {/* Traffic sources */}
                <div className="card">
                    <div className="card-header header-elements-inline">
                    <h6 className="card-title">Blogs List</h6>
                    <div className="header-elements">
                        <div className="form-check form-check-right form-check-switchery form-check-switchery-sm">
                        
                        </div>
                    </div>
                    </div>
                    <div className="card-body ">
                        <DataTable
                        columns={columns}
                        data={blogs}
                        pagination
                    />
                    </div>
                </div>
                </div>
                
            </div>   
        </>
    );
};

export default CustomerListComponent;