import React from "react";
import { Redirect, Route } from "react-router";

// const AUTH_USER_HOMEPAGE = "";

export function PrivateRoutes({ path, component: Component, ...rest }) {
    console.log("--------------------")
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) return <Route path={path} {...rest} component={() => <Component />} />;
    else return <Redirect to="/" />;
}

export function PublicRoutes({ path, component: Component, ...rest }) {
    // console.log("--------------------oooooooooooo")
    // const userData = JSON.parse(localStorage.getItem("userData"));
    // if (userData) return <Redirect to={AUTH_USER_HOMEPAGE} />;
    // else return <Route path={path} {...rest} component={() => <Component />} />;
    return <Route path={path} {...rest} component={() => <Component />} />;
}
