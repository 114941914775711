import React, {useEffect, useState} from 'react';
import { useFormik } from "formik";
import {allCategoris} from '../../../model/category/Category'
import {blogCreate, cronBlogMeta} from '../../../model/blog/Blog'
import {UploadToS3} from '../../../model/s3-upload/UploadS3'
import { CKEditor,UploadAdapter} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import cogoToast from 'cogo-toast';

import Resizer from 'react-image-file-resizer';
import Amplify, { Auth, Hub, API, graphqlOperation, Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

const BlogAddComponent = () => {
    const [loader, setloader]=useState(false);
    const [categoris, setCategoris] = useState();
    useEffect(async() =>{
        let category = await allCategoris();
        setCategoris(category.data);
        console.log(category, '-------------category')
    },[])

    const validateForm = (values) => { 
        const errors = {};
        if (!values.title) errors.title = "Blog title is required";
        if (!values.blog_thumbnail) errors.blog_thumbnail = "Blog thumbnail is required";
        if (!values.category) errors.category = "Category is required";
        if (!values.reading_time) errors.reading_time = "Reading Time is required";
        if (!values.published_date) errors.published_date = "Published Date is required";
        if (!values.short_description) errors.short_description = "Short Description is required";
        if (!values.description) errors.description = "Description is required";
        if (!values.tags) errors.tags = "Tags is required"; 
        if (!values.published_status) errors.published_status = "Published status is required";
        if (!values.blog_keyword) errors.blog_keyword = "Blog Keyword is required";
        if (!values.blog_meta_description) errors.blog_meta_description = "Blog Meta Description is required";
       
        return errors;
    };
    
    const submitForm = async (values,{resetForm}) => {
        // console.log(values, '------------------values')
        setloader(true);
        let response = await blogCreate(values);
        if(response.status)
        {
          resetForm();
          setloader(false);
          cogoToast.success("Successfully created");
          await cronBlogMeta();
  
        }else
        {
            cogoToast.error( `Check duplicated title or something went wrong` );
        }
        setloader(false);
    //    console.log(response, '--------response');
      
    };

    
    const formik = useFormik({
        initialValues: {
            title: "", 
            blog_thumbnail: "",
            blog_keyword: "",
            category:"",
            reading_time:"",
            published_date:"",
            short_description:"", 
            description:"",
            tags:"",
            published_status:"1",
            blog_meta_description:""
        },
        validateOnBlur: true,
        validate: validateForm,
        onSubmit: submitForm,
    });
    const onchangetextData =async(event, editor)=>{
      
            const data = editor.getData();
            console.log( data , '-------------');
            formik.values.description = data;        
    }
    const oncBlurtextData = async(event, editor)=>{
            console.log('Blur.',event.target, editor );
    }
    const onFocustextData = async(event, editor)=>{
            console.log( 'Focus.',event.target, editor );
    }
    
    const thumbUploadS3 =async(e)=>{
        // let response = await UploadToS3(e.target.files[0]);

        Resizer.imageFileResizer(e.target.files[0],
            575,
            600,
            'WEBP',
            100,
            0,
            async uri => {
              try {
                var storage = await Storage.put( uuidv4() + "_"+ e.target.files[0].name,
                  uri,
                  {
                    contentType: 'image/*'
                  }
                );
    
                if(storage.key){

                  let url = `https://doodlelatestbuckte102925-prod.s3.ap-southeast-1.amazonaws.com/public/${storage.key}`
                  document.getElementById("feature_image").value = url
                  formik.values.blog_thumbnail =  url
                  cogoToast.success("Success !");
                  setloader(false);
                }else{
                    cogoToast.error("Failed !");
                }

              } catch (err) {

                cogoToast.error("Failed !");
              }
    
            },
            'file'
        )
    }

    return (
        <>
          <div className="row">
            <div className="col-xl-12">
            {/* Traffic sources */}
            <div className="card">
                <div className="card-header header-elements-inline">
                <h6 className="card-title">Add New Blog</h6>
                <div className="header-elements">
                    <div className="form-check form-check-right form-check-switchery form-check-switchery-sm">
                    
                    </div>
                </div>
                </div>
                <div className="card-body ">
                  {formik.touched.serverError && formik.errors.serverError && (
                        <label className="text-danger">
                            {formik.errors.serverError}
                        </label>
                    )}
                <form action="" onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                        <label> Blog Title</label>
                        <input type="text" className="form-control" placeholder="Blog Title"
                         id="title"
                         value={formik.values.title}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        />
                         {formik.touched.title && formik.errors.title && (
                            <label className="text-danger">
                                {formik.errors.title}
                            </label>
                        )}
                    </div>

                    <div className="form-group">
                        <label> Blog Meta Keyword</label>
                        <input type="text" className="form-control" placeholder="Blog Meta keyword"
                         id="blog_keyword"
                         value={formik.values.blog_keyword}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        />
                         {formik.touched.blog_keyword && formik.errors.blog_keyword && (
                            <label className="text-danger">
                                {formik.errors.blog_keyword}
                            </label>
                        )}
                    </div>

                    <div className="form-group">
                        <label> Blog Meta Description</label>
                        <input type="text" className="form-control" placeholder="Blog Meta Description"
                         id="blog_meta_description"
                         value={formik.values.blog_meta_description}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        />
                         {formik.touched.blog_meta_description && formik.errors.blog_meta_description && (
                            <label className="text-danger">
                                {formik.errors.blog_meta_description}
                            </label>
                        )}
                    </div>

                    <div className="form-group">
                        <label> Blog Thumbnail</label>
                        <input type="file" className="form-control"
                        id="blog_thumbnail"
                        
                        onChange={(e)=>thumbUploadS3(e)}
                        />
                         {formik.touched.blog_thumbnail && formik.errors.blog_thumbnail && (
                          <label className="text-danger">
                              {formik.errors.blog_thumbnail}
                          </label>
                      )}
                      <input id="feature_image" name="feature_image" value="https://doodlelatestbuckte102925-prod.s3.ap-southeast-1.amazonaws.com/1-Do-you-see-any-cons-in-offshore-team.jpg" type="hidden"  />
                    </div>
                    <div className="form-group">
                        <label> Category</label>
                        {/* <input type="text" className="form-control"
                        id="phone"
                        value={formik.values.phone}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder="Customer Phone"/> */}

                        <select className="form-control"  
                        id="category"
                        value={formik.values.category}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}>
                         <option value="">Select a Category</option>
                         {categoris?.map((item,index)=>{
                             return(<option value={item.id} >{item.name}</option>)
                         })}    
                         </select>

                         {formik.touched.category && formik.errors.category && (
                          <label className="text-danger">
                              {formik.errors.category}
                          </label>
                      )}
                    </div>
                    <div className="form-group">
                        <label> Reading time</label>
                        <input type="text" className="form-control" placeholder="Reading time"
                         id="reading_time"
                         value={formik.values.reading_time}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        />
                         {formik.touched.reading_time && formik.errors.reading_time && (
                            <label className="text-danger">
                                {formik.errors.reading_time}
                            </label>
                        )}
                    </div>
                    <div className="form-group">
                        <label>Published Date</label>
                        <input type="date" className="form-control" placeholder="Published Date"
                         id="published_date"
                         value={formik.values.published_date}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        />
                         {formik.touched.published_date && formik.errors.published_date && (
                            <label className="text-danger">
                                {formik.errors.published_date}
                            </label>
                        )}
                    </div>
                    
                    <div className="form-group">
                        <label> Blog Short Description</label>
                        <textarea type="text" className="form-control" 
                         value={formik.values.short_description}
                         onBlur={formik.handleBlur}
                         onChange={formik.handleChange}
                         id="short_description"
                        placeholder="Blog Short Description">
                        </textarea>
                        {formik.touched.short_description && formik.errors.short_description && (
                          <label className="text-danger">
                              {formik.errors.short_description}
                          </label>
                      )}
                    </div>
                    {/* <div className="form-group">
                        <label> Blog Description</label>
                        <textarea type="text" className="form-control" 
                         value={formik.values.description}
                         onBlur={formik.handleBlur}
                         onChange={formik.handleChange}
                         id="description"
                        placeholder="Blog  Description">
                        </textarea>
                        {formik.touched.description && formik.errors.description && (
                          <label className="text-danger">
                              {formik.errors.description}
                          </label>
                      )}
                    </div> */}
                     <CKEditor
                            editor={ ClassicEditor }
                            data=""
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) =>onchangetextData(event, editor) }
                            onBlur={ ( event, editor ) => oncBlurtextData(event, editor)}
                            onFocus={ ( event, editor ) =>onFocustextData (event, editor) }
                            upload={(event, editor)=>{
                                console.log( 'Uploaded', event , editor );
                            }}

                            config={{ckfinder: {
                             
                                uploadUrl: process.env.REACT_APP_BACK_END+'/s3-image-upload'
                            }}}

                            className="form-control"
                        />
                         {formik.touched.description && formik.errors.description && (
                          <label className="text-danger">
                              {formik.errors.description}
                          </label>
                      )}


                    <div className="form-group">
                        <label> Blog Tags</label>
                        <textarea type="text" className="form-control" 
                         value={formik.values.tags}
                         onBlur={formik.handleBlur}
                         onChange={formik.handleChange}
                         id="tags"
                        placeholder="Blog  Tags">
                        </textarea>
                        {formik.touched.tags && formik.errors.tags && (
                          <label className="text-danger">
                              {formik.errors.tags}
                          </label>
                      )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Published Status</label>
                        <select className="form-control" name
                        value={formik.values.published_status}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        id="published_status"
                        >
                            <option value="1">Published</option>
                            <option value="0">Draft</option>
                        </select>
                        {formik.touched.published_status && formik.errors.published_status && (
                            <label className="text-danger">
                                {formik.errors.published_status}
                            </label>
                        )}
                    </div>
                   
                    
                    <div className="form-group">
                        {loader?
                             <input disabled type="submit" className="btn btn-primary" value="Save"/>
                        :
                        <input type="submit" className="btn btn-primary" value="Save"/>
                        }
                       
                    </div>

                </form>
                </div>
                <div className="chart position-relative" id="traffic-sources"><div className="d3-tip e" style={{display: 'none'}} /><svg width="920.90625" height={340}><g transform="translate(50,5)" width="920.90625" /></svg></div>
            </div>
            {/* /traffic sources */}
            </div>
            
        </div>
        </>
    );
};

export default BlogAddComponent;