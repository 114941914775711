import React, {useEffect, useState} from 'react';
import {ask_team} from '../../../model/ask-talent/AskTalent'
const MeetingViewComponent = (props) => {
      const [data, setData] = useState([])
  
      useEffect(async()=>{

        let singleTalent = await ask_team(props.id);
        setData(singleTalent.response)
            
      },[]);


console.log("data " , data)

    return (
        <>
        <div className="row">
            <div className="col-xl-12">
            {/* Traffic sources */}
            <div className="card">
                <div className="card-header header-elements-inline">
                <h6 className="card-title">View Meeting Details</h6>
                <div className="header-elements">
                    <div className="form-check form-check-right form-check-switchery form-check-switchery-sm">
                     
                    </div>
                </div>
                </div>
                <div className="card-body">
                    <div className="col-6" style={{ border:"solid" }}>
                    <p>Team Name : {data[0]?.name }</p>  
                    <p>Team Status : 
                        
                    { data[0]?.status == 1 && <span style={{ background:"Green" }}> Meeting scheduled </span> } 
                    { data[0]?.status == 0 && <span > Pending </span> } 
                    { data[0]?.status == 2 && <span > Searching </span> } 
                    { data[0]?.status == 3 && <span style={{ background:"Green" }} > Hiring completed </span> } 
                    { data[0]?.status == 4 && <span style={{ background:"Red" }} > Cancelled </span> } 
                        
                    </p>  


                    <p>Meeting Status : 
                        
                    { data[0]?.meeting_status == 0 && <span style={{ background:"Green" }}> Meeting scheduled </span> } 
                    { data[0]?.meeting_status == 1 && <span style={{ background:"Red" }}> Postpended </span> } 
                    { data[0]?.meeting_status == 2 && <span style={{ background:"Red" }}> Cancelled </span> } 
                    { data[0]?.meeting_status == 3 && <span > Pending </span> } 
                        
                    </p> 


                    <p>Total Monthly Cost : {data[0]?.total_monthly_cost }</p>  
                    <p>Total Cost by employee : {data[0]?.total_cost_by_talent }</p>  
                    <p>SLA Cost : {data[0]?.sla_support_cost }</p> 
                    </div>
                    {data?.map((item, index)=>{
                        return (
                            <div className="col-6">
                                {index+1}.
                                <p>Employee Name : {item.emp_name} </p>
                                <p> <img src={"https://doodlelatestbuckte102925-prod.s3.ap-southeast-1.amazonaws.com/"+item.emp_image} width="40%"/> </p>
                                <p>Employee Salary : { item.salary } </p>
                                <p>Employee Availablity :
                                    { item.emp_status == 1 && <span style={{ background:"Green" }}> Available </span> } 
                                    { item.emp_status == 0 && <span style={{ background:"Red" }}> Busy </span> } 
                                </p>

                                <p>Employee Available From  : { item.available_from } </p>
                                <p>Employee Experence : { item.experence } </p>
                                <p>Employee Expert Developer  : { item.expert_developer } </p>
                                
                            </div>
                        )
                      })}
                </div>
            </div>

            </div>
            
        </div>  
    </>
    );
};

export default MeetingViewComponent;