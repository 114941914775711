import React, {useEffect, useState} from 'react';
import { useFormik } from "formik";
import {create} from '../../../model/customer/Customer'
import cogoToast from 'cogo-toast';

const CustomerAddComponent = () => {
    const [loader, setloader]=useState(false);
    const [afterSubmit, setAfterSubmit]=useState(false);
    const [credential, setCredential]=useState('');

    const validateForm = (values) => {
        const errors = {};
        if (!values.name) errors.name = "Company Name is required";
        // if (!values.website) errors.website = "Company Website is required";
        // if (!values.address) errors.address = "Company Address is required";
        // if (!values.phone) errors.phone = "Company Phone Number is required";
        if (!values.email) errors.email = "Company Email is required";
        if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
            errors.email = "Invalid email";

        if (!values.contact_person_name) errors.contact_person_name = "Name is required";
        // if (!values.contact_person_website) errors.contact_person_website = "Website is required";
        // if (!values.contact_person_address) errors.contact_person_address = "Address is required";
        if (!values.contact_person_phone) errors.contact_person_phone = "Phone Number is required";
        if (!values.contact_person_email) errors.contact_person_email = "Email is required";
        if (values.contact_person_email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.contact_person_email))
            errors.contact_person_email = "Invalid email";

        return errors;
    };

    const generatePassword = () => {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
      }
    
    const submitForm = async (values,{resetForm}) => {
        setloader(true);
        let response = await create(values);
        if(response.status)
        {
          const credentials = 'Your Login Credentials for ' + values.name + ' is \n\nEmail: ' + values.email + ' \nPassword: ' + values.password + '\n\n Thank you.';
          setCredential(credentials);
          setAfterSubmit(true);
          setloader(false);
          resetForm();
          cogoToast.success('Successfully Create Customer!');
        }
       console.log(response, '--------response');
      
    };
    
    const formik = useFormik({
        initialValues: {
            name: "",
            email:"",
            phone:"",
            address:"",
            website:"",
            password: generatePassword(),
            contact_person_name: "",
            contact_person_email:"",
            contact_person_address:"",
            contact_person_website:"",
            contact_person_phone:"",
        },
        validateOnBlur: true,
        validate: validateForm,
        onSubmit: submitForm,
    });
    return (
        <>
          <div className="row">
            <div className="col-xl-6">
            <div className="card">
                <div className="card-header header-elements-inline">
                <h6 className="card-title">Add New Customer</h6>
                <div className="header-elements">
                    <div className="form-check form-check-right form-check-switchery form-check-switchery-sm">
                    
                    </div>
                </div>
                </div>
                <div className="card-body ">
                <form action="" onSubmit={formik.handleSubmit}>
                    <h1>Company Information</h1>
                    <div className="form-group">
                        <label> Company Name</label>
                        <input type="text" className="form-control" placeholder="Company Name"
                         id="name"
                         value={formik.values.name}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        />
                         {formik.touched.name && formik.errors.name && (
                            <label className="text-danger">
                                {formik.errors.name}
                            </label>
                        )}
                    </div>
                    <div className="form-group">
                        <label> Company Email</label>
                        <input type="email" className="form-control"
                        id="email"
                        value={formik.values.email}
                       onBlur={formik.handleBlur}
                       onChange={formik.handleChange}
                        placeholder="Company Email"/>
                         {formik.touched.email && formik.errors.email && (
                          <label className="text-danger">
                              {formik.errors.email}
                          </label>
                      )}
                    </div>
                    
                    <div className="form-group">
                        <label> Company Phone</label>
                        <input type="text" className="form-control"
                        id="phone"
                        value={formik.values.phone}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder="Company Phone"/>
                         {formik.touched.phone && formik.errors.phone && (
                          <label className="text-danger">
                              {formik.errors.phone}
                          </label>
                      )}
                      <p className='text-danger'>Please Enter number with country code like (+880)</p>
                    </div>
                    
                    <div className="form-group">
                        <label> Company Address</label>
                        <textarea type="text" className="form-control" 
                         value={formik.values.address}
                         onBlur={formik.handleBlur}
                         onChange={formik.handleChange}
                         id="address"
                        placeholder="Company Address">
                        </textarea>
                        {formik.touched.address && formik.errors.address && (
                          <label className="text-danger">
                              {formik.errors.address}
                          </label>
                        )}
                    </div>
                    <div className="form-group">
                        <label>Company Website</label>
                        <input type="text" className="form-control" 
                             value={formik.values.website}
                             onBlur={formik.handleBlur}
                             onChange={formik.handleChange}
                             id="website"
                        placeholder="Company Website"/>
                         {formik.touched.website && formik.errors.website && (
                          <label className="text-danger">
                              {formik.errors.website}
                          </label>
                      )}
                    </div>
                    <h1>Contact Person Information</h1>
                        <div className="form-group">
                            <label>Name</label>
                            <input type="text" className="form-control" placeholder="Name"
                            id="contact_person_name"
                            value={formik.values.contact_person_name}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            />
                            {formik.touched.contact_person_name && formik.errors.contact_person_name && (
                                <label className="text-danger">
                                    {formik.errors.contact_person_name}
                                </label>
                            )}
                        </div>
                        <div className="form-group">
                            <label> Email</label>
                            <input type="email" className="form-control"
                            id="contact_person_email"
                            value={formik.values.contact_person_email}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                            placeholder="Email"/>
                            {formik.touched.contact_person_email && formik.errors.contact_person_email && (
                            <label className="text-danger">
                                {formik.errors.contact_person_email}
                            </label>
                        )}
                        </div>
                        <div className="form-group">
                            <label> Phone</label>
                            <input type="text" className="form-control"
                            id="contact_person_phone"
                            value={formik.values.contact_person_phone}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder="Phone"/>
                            {formik.touched.contact_person_phone && formik.errors.contact_person_phone && (
                            <label className="text-danger">
                                {formik.errors.contact_person_phone}
                            </label>
                        )}

                        <p className='text-danger'>Please Enter number with country code like (+880)</p>
                        </div>
                        
                        <div className="form-group">
                            <label> Address</label>
                            <textarea type="text" className="form-control" 
                            value={formik.values.contact_person_address}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            id="contact_person_address"
                            placeholder="Address">
                            </textarea>
                            {formik.touched.contact_person_address && formik.errors.contact_person_address && (
                            <label className="text-danger">
                                {formik.errors.contact_person_address}
                            </label>
                        )}
                        </div>
                      
                        <div className="form-group">
                            <label>Website</label>
                            <input type="text" className="form-control" 
                                value={formik.values.contact_person_website}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                id="contact_person_website"
                            placeholder="Website"/>
                            {formik.touched.contact_person_website && formik.errors.contact_person_website && (
                            <label className="text-danger">
                                {formik.errors.contact_person_website}
                            </label>
                        )}
                        </div>
                    <div className="form-group">
                        {loader?
                             <input disabled type="submit" className="btn btn-primary" value="Save"/>
                        :
                        <input type="submit" className="btn btn-primary" value="Save"/>
                        }

                        {afterSubmit?
                        <>
                            <input type="button" className="btn btn-info ml-2" onClick={ () => {navigator.clipboard.writeText(credential)} } value="Copy"/>
                            {/* <input type="button" className="btn btn-success ml-2" value="Share"/> */}
                        </>
                        :
                            ""
                        }
                       
                        
                    </div>
                </form>
                </div>
            </div>
            </div>
            
        </div>
        </>
    );
};

export default CustomerAddComponent;