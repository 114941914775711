import React from 'react';
import Layout from '../../../components/common/layout/Layout';
import CustomerAddComponent from '../../../components/dashboard/customer/CustomerAddComponent';
const CustomerAdd = () => {
    return (
       <Layout >
           <CustomerAddComponent />
       </Layout>
    );
};

export default CustomerAdd;