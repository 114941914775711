import React from 'react';

const Footer = () => {
    return (
        <>
           <div className="navbar navbar-expand-lg navbar-light">
              <div className="text-center d-lg-none w-100">
                <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
                  <i className="icon-unfold mr-2" />
                  Footer
                </button>
              </div>
              <div className="navbar-collapse collapse" id="navbar-footer">
                <span className="navbar-text">
                  © 2021 - 2022. <a target="_blank" rel="noreferrer" href="https://www.thedoodleinc.com/">The Doodle INC</a> 
                </span>
                
              </div>
            </div> 
        </>
    );
};

export default Footer;