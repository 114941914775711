import React from 'react';
import Layout from '../../../components/common/layout/Layout';
import BlogAddComponent from '../../../components/dashboard/blog/BlogAddComponent'

const BlogAdd = () => {
    return (
        <Layout>
            <BlogAddComponent />
        </Layout>
    );
};

export default BlogAdd;