import axios from 'axios';

export async function list(){
let list = await axios.get(process.env.REACT_APP_BACK_END+"/blogs");    
return list.data;
}

export async function all_designations(){
    let list = await axios.get(process.env.REACT_APP_BACK_END+"/all_designations");    
    return list.data;
}





export async function getBlogBySlug(slug){
let list = await axios.get(process.env.REACT_APP_BACK_END+"/blog/" + slug);    
return list.data;
}

export async function cronBlogMeta(){
    let list = await axios.get('https://thedoodleinc.com/api/hello');    
    return list.data;
}

export async function blogCreate(data)
{
    try {
    let response = await axios.post(process.env.REACT_APP_BACK_END+'/blog-create', data);
    return response;
    }catch (err) {
        return {err:err.message};
    }
} 

export async function blogEdit(data)
{
    try {
    let response = await axios.put(process.env.REACT_APP_BACK_END+'/blog-edit', data);
    console.log(response);
    return response;
    }catch (err) {
        return {err:err.message};
    }
} 
