import Login from './../pages/login/Login'
import Logout from '../pages/dashboard/Logout'
import Dashboard from '../pages/dashboard/Dashboard'
import EmployeeAdd from '../pages/dashboard/employee/EmployeeAdd'
import EmployeeList from '../pages/dashboard/employee/EmployeeList'
import EmployeeEdit from '../pages/dashboard/employee/EmployeeEdit'
import CustomerAdd from './../pages/dashboard/customer/CustomerAdd'
import CustomerList from '../pages/dashboard/customer/CustomerList'
import CustomerEdit from '../pages/dashboard/customer/CustomerEdit'
import TalentList from './../pages/dashboard/ask-talent/TalentList'
import TalentEdit from '../pages/dashboard/ask-talent/TalentEdit'
import MeetingList from './../pages/dashboard/meeting/MeetingList'
import TeamList from './../pages/dashboard/meeting/TeamList'
import MeetingEdit from './../pages/dashboard/meeting/MeetingEdit'

import MeetingView from './../pages/dashboard/meeting/MeetingView'

import BlogList from './../pages/dashboard/blog/BlogList'
import BlogAdd from './../pages/dashboard/blog/BlogAdd'
import BlogEdit from '../pages/dashboard/blog/BlogEdit'


import DesignationList from './../pages/dashboard/designation/DesignationList'
import DesignationAdd from './../pages/dashboard/designation/DesignationAdd'
import DesignationEdit from '../pages/dashboard/designation/DesignationEdit'


export const publicRoutes =[
    {
        url: "/",
        private:false,
        component:Login
    },
    {
        url: "/login",
        private:false,
        component:Login
    },
    {
        url: "/Logout",
        private:true,
        component:Logout
    },
    {
        url: "/Dashboard",
        private:true,
        component:Dashboard
    },
    {
        url:"/Employee-add",
        private:true,
        component:EmployeeAdd,
    },
    {
        url:"/Employee-list",
        private:true,
        component:EmployeeList,
        
    },
    {
        url:"/Employee-edit/:id",
        private:true,
        component:EmployeeEdit,
    },
    {
        url:"/employee-get/:id",
        private:true,
    },
    {
        url:"/Customer-add",
        private:true,
        component:CustomerAdd,
    },
    {
        url:"/Customer-list",
        private:true,
        component:CustomerList,
    },
    {
        url:"/edit-customer/:id",
        private:true,
        component:CustomerEdit

    },
    {
        url:"/ask-talent-list",
        private:true,
        component:TalentList,
    },
    {
        url:"/edit-askTalent/:id",
        private:true,
        component:TalentEdit,
    },
    {
        url:"/Meeting-List",
        private:true,
        component:MeetingList,
    },
    {
        url:"/edit-meeting/:id",
        private:true,
        component:MeetingEdit,
    },

    {
        url:"/Meeting-List-details/:id",
        private:true,
        component:MeetingView,
    },

    {
        url:"/Blog-List",
        private:true,
        component:BlogList,
    },
    {
        url:"/Blog-Add",
        private:true,
        component:BlogAdd,
    },
    {
        url:"/edit-blog/:slug",
        private:true,
        component:BlogEdit,
    },

    {
        url:"/designations-List",
        private:true,
        component:DesignationList,
    },

    {
        url:"/designations-Add",
        private:true,
        component:DesignationAdd,
    },

    {
        url:"/edit-designations/:slug",
        private:true,
        component:DesignationEdit,
    },
    {
        url:"/team-List",
        private:true,
        component:TeamList,
    }

    
]