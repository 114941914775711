import React, {useEffect, useState} from 'react';
import {allDepartments} from '../../../model/department/Department'
import {find_ask_talent, updateTalent} from '../../../model/ask-talent/AskTalent'
import {all_designations} from '../../../model/designation/Designation'
import { useFormik } from "formik";
import axios from 'axios';
import { Modal } from 'react-bootstrap'
import cogoToast from 'cogo-toast';
const TalentEditComponent = (props) => {
      const [departments, setDepartments] = useState()
      const [asktalentSingle, setAsktalentSingle] = useState()
      const [designations, setDesignations] = useState()
      const [skills, setSkills] = useState()
      const [loader, setloader]=useState(false);
      const [modalData, setmodalData] = useState([]);
      const [show, setShow] = useState(false);
  
      const handleClose = () => setShow(false);
  
      function findUserData(user_id) {
          axios.get(process.env.REACT_APP_BACK_END+"/customers/" + user_id).then(res => {
              setmodalData(res.data);
              setShow(true);
          }).catch(function (error) {
              cogoToast.error(error.response.data.message , { position: 'top-right' } );
          });
      }
      useEffect(async()=>{
            let departmentsData = await allDepartments();
            console.log(departmentsData, '---------');
             setDepartments(departmentsData);
             let singleTalent = await find_ask_talent(props.id);
             setAsktalentSingle(singleTalent.response);
             let allDesignationsData =await all_designations();
             setDesignations(allDesignationsData);
            //  console.log(singleTalent.response, '--------------singleTalent')
            // console.log(), 'asktalentSingle?.required_skill'
            if(asktalentSingle?.required_skill){
                let skill= asktalentSingle?.required_skill.replaceAll('[', '');
                let skill2 = skill.replaceAll(']', '');
                let skill3= skill2.replaceAll('"', '');
                // console.log(skill3, '----------asktalentSingle?.required_skill')
                setSkills({skill:skill3})
            }
            
      },[]);
      const validateForm = (values) => {
        const errors = {};
        // if (!values.depertment_id) errors.name = "Customer Name is required";
        // if (!values.total_talent) errors.website = "Website is required";
        // if (!values.address) errors.address = "Customer Address is required";
        // if (!values.phone) errors.phone = "Phone Number is required";
       
        // if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
        //     errors.email = "Invalid email";

        return errors;
    };

      const submitForm = async (values,{resetForm}) =>{
          console.log(values)
            setloader(true);
            let response = await updateTalent(values);
            if(response.status)
            {
                cogoToast.success("Data Successfully Updated!");
            //   resetForm();
                setloader(false);
        
            }else
            {
                cogoToast.error("Failed !"); 
            }
        // console.log(response, '--------response');
        
    };
    const required_skill =(data) => {
        let skill= data.replaceAll('[', '');
        let skill2 = skill.replaceAll(']', '');
        let skill3= skill2.replaceAll('"', '');
        return skill3;
    }
    // console.log(customer, '------------customer');
    const formik = useFormik({
        initialValues: {
            id: asktalentSingle?.id,
            depertment_id: asktalentSingle?.depertment_id,
            total_talent:asktalentSingle?.total_talent,
            certification:asktalentSingle?.certification,
            designation_id:asktalentSingle?.designation_id,
            required_skill:asktalentSingle?required_skill(asktalentSingle?.required_skill):"",
            status:asktalentSingle?.status,
        },
        enableReinitialize: true,
        validateOnBlur: true,
        validate: validateForm,
        onSubmit: submitForm,
    });
    return (
        <>
        <div className="row">
            <div className="col-xl-12">
            {/* Traffic sources */}
            <div className="card">
                <div className="card-header header-elements-inline">
                <h6 className="card-title">Edit</h6>
                <div className="header-elements">
                    <div className="form-check form-check-right form-check-switchery form-check-switchery-sm">
                    
                    </div>
                </div>
                </div>
                <div className="card-body ">
                   <form action="" onSubmit={formik.handleSubmit}>
                       {/* <div className="form-group">
                           <label htmlFor="" >Department </label>
                           <select className="form-control"
                             id="depertment_id"
                             value={formik.values.depertment_id}
                             onBlur={formik.handleBlur}
                             onChange={formik.handleChange}
                           >
                               <option value="">Select a Department</option>
                                {departments?.map((item, index)=>{
                                    return (<option value={item.id}    >{item.name}</option>)
                                })}
                           </select>
                           {formik.touched.depertment_id && formik.errors.depertment_id && (
                                <label className="text-danger">
                                    {formik.errors.depertment_id}
                                </label>
                            )}
                       </div>
                       <div className="form-group">
                           <label htmlFor="" ><a onClick={() => findUserData(asktalentSingle?.user_id)}>{asktalentSingle?.user_name}</a></label>
                           
                       </div> */}
                       {/* <div className="form-group">
                           <label htmlFor="">Total Talent</label>
                           <input type="text" className="form-control"  
                            id="total_talent"
                            value={formik.values.total_talent}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                           />
                           {formik.touched.total_talent && formik.errors.total_talent && (
                                <label className="text-danger">
                                    {formik.errors.total_talent}
                                </label>
                            )}
                       </div> */}
                       {/* <div className="form-group">
                           <label htmlFor="">Certification</label>
                           <input type="text" className="form-control"  
                            id="certification"
                            value={formik.values.certification}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                           />
                           {formik.touched.certification && formik.errors.certification && (
                                <label className="text-danger">
                                    {formik.errors.certification}
                                </label>
                            )}
                       </div> */}
                       {/* <div className="form-group">
                           <label htmlFor="" >Designation </label>
                           <select name="Designation" className="form-control"  
                            id="designation_id"
                            value={formik.values.designation_id}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange} 
                            >
                               <option value="">Select a Designation</option>
                                {designations?.map((item, index)=>{
                                    return (<option value={item.id}    >{item.name}</option>)
                                })}
                           </select>
                           {formik.touched.designation_id && formik.errors.designation_id && (
                                <label className="text-danger">
                                    {formik.errors.designation_id}
                                </label>
                            )}
                       </div> */}
                       {/* <div className="form-group">
                           <label htmlFor="">Required Skill</label>
                           <textarea className="form-control"  
                            id="required_skill"
                            readOnly
                            value={formik.values.required_skill}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange} ></textarea>
                            {formik.touched.required_skill && formik.errors.required_skill && (
                                <label className="text-danger">
                                    {formik.errors.required_skill}
                                </label>
                            )}
                       </div> */}
                       {/* <div className="form-group">
                           <label htmlFor="">Notes</label> 
                           <textarea className="form-control"
                            defaultValue={asktalentSingle?.notes}
                            onChange={formik.handleChange}
                            ></textarea>
                       </div> */}
                       <div className="form-group">
                           <label htmlFor="">Status</label>
                           <select  className="form-control"
                            id="status"
                            value={formik.values.status}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                           >
                                <option value="0">Pending</option>
                                <option value="1">Meeting scheduled</option>
                                <option value="2">Searching</option>
                                <option value="3">Hiring completed</option>
                                <option value="4">Cancelled</option>
                           </select>
                           {formik.touched.status && formik.errors.status && (
                                <label className="text-danger">
                                    {formik.errors.status}
                                </label>
                            )}
                       </div>
                       <div className="form-group">
                           {loader==true?
                           <input value="Update" type="submit" disabled className="btn btn-primary" />
                           :
                           <input value="Update" type="submit" className="btn btn-primary" />
                           }
                           
                       </div>
                   </form>
                </div>
                <div className="chart position-relative" id="traffic-sources"><div className="d3-tip e" style={{display: 'none'}} /><svg width="920.90625" height={340}><g transform="translate(50,5)" width="920.90625" /></svg></div>
            </div>
            {/* /traffic sources */}
            </div>
            
        </div>   <Modal show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                <Modal.Title>User Details</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6">
                                <p><b>Name:</b> {modalData.contact_person_name}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Phone:</b> {modalData.contact_person_phone}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Email:</b> {modalData.contact_person_email}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Website:</b> {modalData.contact_person_website}</p>
                            </div>
                            <div className="col-md-12">
                                <p><b>Address:</b> {modalData.contact_person_address}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Company Name:</b> {modalData.name}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Company Phone:</b> {modalData.phone}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Company Email:</b> {modalData.email}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Company Website:</b> {modalData.website}</p>
                            </div>
                            <div className="col-md-12">
                                <p><b>Company Address:</b> {modalData.address}</p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
    </>
    );
};

export default TalentEditComponent;