import React from 'react';
import Footer from '../footer/Footer';
import Header from '../header/Header'
import LeftSidebar from '../sidebar/LeftSidebar';
const Layout = ({children}) => {
    return (
        <>
        <Header />
        {/* /main navbar */}
        {/* Page content */}
        <div className="page-content">
          {/* Main sidebar */}
          <div className="sidebar sidebar-light sidebar-main sidebar-expand-md">
            {/* Sidebar mobile toggler */}
            <div className="sidebar-mobile-toggler text-center">
              <a href="#" className="sidebar-mobile-main-toggle">
                <i className="icon-arrow-left8" />
              </a>
              Navigation
              <a href="#" className="sidebar-mobile-expand">
                <i className="icon-screen-full" />
                <i className="icon-screen-normal" />
              </a>
            </div>
            {/* /sidebar mobile toggler */}
            {/* Sidebar content */}
            <LeftSidebar/>
            {/* /sidebar content */}
          </div>
          {/* /main sidebar */}
          {/* Main content */}
          <div className="content-wrapper">
            {/* Content area */}
            <div className="content pt-0">
             
              {/* Dashboard content */}
              <div className="row">
                {children}
              </div>
              {/* /dashboard content */}
            </div>
            {/* /content area */}
            {/* Footer */}
            <Footer />
            {/* /footer */}
          </div>
          {/* /main content */}
        </div>
        </>
    );
};

export default Layout;