import React from "react";

const Header = () => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  return (
    <>
      <div className="navbar navbar-expand-md navbar-light navbar-static">
        <div className="navbar-brand">
          <a href="/Dashboard" className="d-inline-block">
            <img src="assets/global_assets/images/logo_light.png" alt="" />
          </a>
        </div>
        <div className="d-md-none">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbar-mobile"
          >
            <i className="icon-tree5" />
          </button>
          <button
            className="navbar-toggler sidebar-mobile-main-toggle"
            type="button"
          >
            <i className="icon-paragraph-justify3" />
          </button>
        </div>
        <div className="collapse navbar-collapse" id="navbar-mobile">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                href="#"
                className="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block"
              >
                <i className="icon-paragraph-justify3" />
              </a>
            </li>
            <li className="nav-item dropdown">
            </li>
          </ul>
          <span className="badge bg-success my-3 my-md-0 ml-md-3 mr-md-auto" >
            Online
          </span>
          <ul className="navbar-nav">
           
            <li className="nav-item dropdown dropdown-user">
              <a
                href="#"
                className="navbar-nav-link d-flex align-items-center dropdown-toggle"
                data-toggle="dropdown"
              >
                <img
                  src="assets/global_assets/images/placeholders/placeholder.jpg"
                  className="rounded-circle mr-2"
                  height={34}
                  alt=""
                />
                <span>{userData.name}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <a href="/Logout" className="dropdown-item">
                  <i className="icon-switch2" /> Logout
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
