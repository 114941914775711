import React from 'react';
import Layout from '../../../components/common/layout/Layout';
import EmployeeAddComponent from '../../../components/dashboard/employee/EmployeeAddComponent';

const EmployeeAdd = () => {
    return (
        <Layout>
            <EmployeeAddComponent />
        </Layout>
    );
};

export default EmployeeAdd;