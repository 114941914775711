import axios from 'axios';
export async function askTalentList(data)
{
 try {
    let response = await axios.get(process.env.REACT_APP_BACK_END+'/all_askTalent_with_join');
        return {response: response.data, status:true};
    }catch(e) {
        return {error: e, status:false};
    }
    
} 
export async function askTalentDelete(id)
{
 try {
    let response = await axios.delete(process.env.REACT_APP_BACK_END+'/ask_talent_delete/'+id);
        return {response: response.data, status:true};
    }catch(e) {
        return {error: e, status:false};
    }
    
} 

export async function find_ask_talent(id)
{
 try {
    let response = await axios.get(process.env.REACT_APP_BACK_END+'/find_ask_talent/'+id);
        return {response: response.data, status:true};
    }catch(e) {
        return {error: e, status:false};
    }
    
} 


export async function ask_team(id)
{
 try {
    let response = await axios.get(process.env.REACT_APP_BACK_END+'/find_team/'+id);
        return {response: response.data, status:true};
    }catch(e) {
        return {error: e, status:false};
    }
    
} 

export async function updateTalent(data)
{

 try {
    let response = await axios.put(process.env.REACT_APP_BACK_END+'/ask_talent_status_change/',{id:data.id, status:data.status});
    console.log(response, 'response')
        return {response: response.data, status:true};
    }catch(e) {
        return {error: e, status:false};
    }
    
} 

