import React from 'react';

const LeftSidebar = () => {
  const userData = JSON.parse(localStorage.getItem('userData'));
    return (
        <>
           <div className="sidebar-content">
              {/* User menu */}
              <div className="sidebar-user">
                <div className="card-body">
                  <div className="media">
                    <div className="mr-3">
                      <a href="/Dashboard"><img src="assets/global_assets/images/placeholders/placeholder.jpg" width={38} height={38} className="rounded-circle" alt="" /></a>
                    </div>
                    <div className="media-body">
                      <div className="media-title font-weight-semibold">{userData.name}</div>
                      <div className="font-size-xs opacity-50">
                        <i className="icon-pin font-size-sm" /> &nbsp; {userData.address}
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
              {/* /user menu */}
              {/* Main navigation */}
              <div className="card card-sidebar-mobile">
                <ul className="nav nav-sidebar" data-nav-type="accordion">
                  {/* Main */}
                  <li className="nav-item-header"><div className="text-uppercase font-size-xs line-height-xs">Main</div> <i className="icon-menu" title="Main" /></li>
                  <li className="nav-item">
                    <a href="/Dashboard" className="nav-link active">
                      <i className="icon-home4" />
                      <span>
                        Dashboard
                      </span>
                    </a>
                  </li>
                  <li className="nav-item nav-item-submenu">
                    <a  className="nav-link"><i className="icon-users"></i> <span>Employees</span></a>

                    <ul className="nav nav-group-sub" data-submenu-title="Layouts">
                      <li className="nav-item"><a href="/Employee-add" className="nav-link">Add Employee</a></li>
                      <li className="nav-item"><a href="/Employee-list" className="nav-link">Employee list </a></li>
                      
                    </ul>
                  </li>
                  <li className="nav-item nav-item-submenu">
                    <a  className="nav-link"><i className="icon-user"></i> <span>Customers</span></a>
                    <ul className="nav nav-group-sub" data-submenu-title="Layouts">
                      <li className="nav-item"><a href="/Customer-add" className="nav-link">Add Customer</a></li>
                      <li className="nav-item"><a href="/Customer-list" className="nav-link">Customer list </a></li>
                    </ul>
                  </li>
                  <li className="nav-item nav-item-submenu">
                    <a className="nav-link"><i className="icon-copy"></i> <span>Ask Talent</span></a>
                    <ul className="nav nav-group-sub" data-submenu-title="Layouts">
                      <li className="nav-item"><a href="/ask-talent-list" className="nav-link">Ask Talent List </a></li>
                    </ul>
                  </li>
                  <li className="nav-item nav-item-submenu">
                    <a className="nav-link"><i className="icon-alarm"></i> <span>Meeting</span></a>
                    <ul className="nav nav-group-sub" data-submenu-title="Layouts">
                      <li className="nav-item"><a href="/Meeting-List" className="nav-link">Meeting List </a></li>
                      <li className="nav-item"><a href="/team-List" className="nav-link">Team List </a></li>
                    </ul>
                  </li>

                  <li className="nav-item nav-item-submenu">
                    <a className="nav-link"><i className="icon-book"></i> <span>Designations</span></a>
                    <ul className="nav nav-group-sub" data-submenu-title="Layouts">
                      <li className="nav-item"><a href="/designations-Add" className="nav-link">Add Designations </a></li>
                      <li className="nav-item"><a href="/designations-List" className="nav-link">Designation List</a></li>
                    </ul>
                  </li>

                  <li className="nav-item nav-item-submenu">
                    <a className="nav-link"><i className="icon-book"></i> <span>Blog</span></a>
                    <ul className="nav nav-group-sub" data-submenu-title="Layouts">
                      <li className="nav-item"><a href="/Blog-Add" className="nav-link">Add Blog </a></li>
                      <li className="nav-item"><a href="/Blog-List" className="nav-link">Blog List</a></li>
                    </ul>
                  </li>

                 
                  {/* /page kits */}
                </ul>
              </div>
              {/* /main navigation */}
            </div> 
        </>
    );
};

export default LeftSidebar;