import React from 'react';
import Layout from '../../../components/common/layout/Layout';
import MeetingViewComponent from '../../../components/dashboard/meeting/MeetingViewComponent'
import { useParams } from "react-router-dom";

const MeetingView = () => {
    let { id } = useParams();
    return (
       <Layout>
           <MeetingViewComponent id={id}/>
       </Layout>
    );
};

export default MeetingView;