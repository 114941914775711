import React from 'react';

import CustomerEditComponent from '../../../components/dashboard/customer/CustomerEditComponent';
import { useParams } from "react-router-dom";
import Layout from '../../../components/common/layout/Layout';

const CustomerEdit = () => {
    let { id } = useParams();
    
    return (
        <Layout>
            <CustomerEditComponent id={id} />
        </Layout>
    );
};

export default CustomerEdit;