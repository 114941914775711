import React,{useEffect, useState} from 'react';
import {list,deleteCustomerByID , resetCredintial} from '../../../model/customer/Customer'
import DataTable from 'react-data-table-component';

import deleteImg from '../../../image/delete.png' 
import editImg from '../../../image/edit.png'
import reset from '../../../image/reset.png'
import Tik from '../../../image/Tik.png'
import Close from '../../../image/Close.png'

const CustomerListComponent = () => { 
    const [customers, setCustomers] = useState();
    const [newPassword, setnewPassword] = useState('');
    const [refresh, setRefresh] = useState(true);
    const [data, setData] = useState(true);
    const [filteredData, setfilteredData] = useState(false);

    useEffect(async() =>{
        let customerList = await list();
        setCustomers(customerList);
    },[]);

    const columns = [
        {
            name: 'Company Name',
            cell: row => row.name
        },
        {
            name: 'Company Email',
            selector: row => row.email,
        },
        {
            name: 'Contact Name',
            selector: row => row.contact_person_name,
        },
        {
            name: 'Contact Email',
            selector: row => row.contact_person_email,
        },
        {
            name: 'Expire ',
            selector: row => formatDate(row.expire_at)
            
        },
        {
            name: "Action",
            cell: row =>
            <div className='row'>
                <a title='Reset credential' onClick={() => {if(window.confirm('Are you sure to reset credentials?')){credentials(row.id)};}}> <img src={reset} width="12px" /> </a> 
                <a title='Edit' href={"/edit-customer/"+row.id} >  <img src={editImg} width="12px" />  </a> 
                <a title='Delete' onClick={() => {if(window.confirm('Are you sure to Delete this?')){DeleteCustomer(row.id)};}}><img src={deleteImg} width="12px" /></a>


                {(() => {
                if (new Date() <= new Date(row.expire_at) ) {
                    return (
                        <>
                        <img src={Tik} width="12px" />
                        </>
                    )
                }else{
                    return <img src={Close} width="12px" />
                }
            })()}

            </div>
        }
    ]

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    const DeleteCustomer =async(id)=>
    {
        await deleteCustomerByID(id);
        let customerList = await list();
        setCustomers(customerList);
    }

    const credentials =async(id)=>
    {
        await resetCredintial(id, (response) => {
            if(response.data)
            {
                setnewPassword(response.data)
            }else
            {
                setnewPassword("something went wrong")
            }
            });
    }
    function serach(e) {
        let val = e.target.value  
        const result = customers.filter(word => (word.name.includes(val) || word.email.includes(val)  || word.contact_person_name.includes(val) || word.contact_person_email.includes(val)  ) );
        setData(result)
        

        setfilteredData(false)
        if (val) {
            setfilteredData(true)
        } 
        
        setRefresh(!refresh)

    }
    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                {/* Traffic sources */}
                    <div className="card">
                        <div className="card-header header-elements-inline">
                        <h6 className="card-title">Customers List</h6>
                        <div className="header-elements">
                            <div className="form-check form-check-right form-check-switchery form-check-switchery-sm">
                                <input onChange={serach} placeholder='Search' /> <br />
                                { newPassword }
                            </div>
                        </div>
                        </div>
                        <div className="card-body ">


                        {(() => {
                            if (filteredData) {
                                return (
                                    <>
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            pagination
                                            responsive
                                        />
                                    </>
                                )
                            }else{
                                return (
                                    <>
                                    
                                    <DataTable
                                        columns={columns}
                                        data={customers}
                                        pagination
                                        responsive
                                    />
                                    
                                    </>
                                )
                            }
                        })()}

                            
                        </div>
                    </div>
                </div>
                
            </div>   
        </>
    );
};

export default CustomerListComponent;