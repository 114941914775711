import React from 'react';
import Layout from '../../../components/common/layout/Layout';
import { useParams } from "react-router-dom";
import EmployeeEditComponent from '../../../components/dashboard/employee/EmployeeEditComponent';

const EmployeeEdit = () => {
    let { id } = useParams();
    return (
        <Layout>
            <EmployeeEditComponent id={id}/>
        </Layout>
    );
};

export default EmployeeEdit;