import React from 'react';
import Layout from '../../../components/common/layout/Layout';
import DesignationAddComponent from '../../../components/dashboard/designation/DesignationAddComponent'

const BlogAdd = () => {
    return (
        <Layout>
            <DesignationAddComponent />
        </Layout>
    );
};

export default BlogAdd;