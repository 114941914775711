import React from 'react';
import Layout from '../../../components/common/layout/Layout';
import DesignationListComponent from '../../../components/dashboard/designation/DesignationListComponent'

const DesignationList = () => {
    return (
        <Layout>
            <DesignationListComponent />
        </Layout>
    );
};

export default DesignationList;